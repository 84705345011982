
import { useState } from 'react';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { isAfter, isSameDay } from 'date-fns';
import { StyledDialog } from '@blackhyve/common';
import { parseDate } from '@blackhyve/utilities/dates';
import ProcurementDataGridTable from './ProcurementDataGridTable';
import { useGetTaskProcurementsQuery } from '../store/procurement.api';

/**
 * Procurement Task Dialog
 * @param {*} param0 
 * @returns 
 */
const ProcurementDialog = ({ taskId, startDate, open, handleClose }) => {

  const [filters, setFilters] = useState({ status: null, page: 1, per_page: 50, order_by: 'created_at,desc' });

  const { data: taskProcurements = [], isLoading, isFetching } = useGetTaskProcurementsQuery({ taskId })

  // const { data: { data: taskProcurements = [], meta: { total = 0 } = {} } = {}, isLoading, isFetching } =
  //   useGetTaskProcurementsQuery({
  //     taskId,
  //     ...pickBy(filters, identity)
  //   })

  const procurementsAtRisk = startDate && taskProcurements?.length
    ? taskProcurements.filter(({ arrival_date }) => {
      const arrivalDate = arrival_date ? parseDate(arrival_date) : null;
      return arrivalDate && (isAfter(arrivalDate, startDate) || isSameDay(arrivalDate, startDate));
    })
    : taskProcurements;


  return <StyledDialog handleClose={handleClose} maxWidth={'xl'} open={open} title={'Procurements At Risk'}>
    <Grid item xs={12}>
      <Paper elevation={3}>
        <ProcurementDataGridTable
          disableAdd={true}
          disableToolBar={true}
          filters={filters}
          isFetching={isFetching}
          isLoading={isLoading}
          rows={procurementsAtRisk}
          setFilters={setFilters}
          total={procurementsAtRisk.length}

        />
      </Paper>
    </Grid>

  </StyledDialog>


}

export default ProcurementDialog;