import { useState } from 'react';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import Check from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { StyledMenu } from 'features/activityFeeds/assets/activityFeedStyle';


/**
 * Single Select Menu
 * @param {String} label
 * @param {String} options
 * @param {Object} icon
 * @param {Object} selected
 * @param {Object} handleSelect
 * @returns 
 */
const SingleSelectMenu = ({ selected, label, options = [], icon, handleSelect }) => {

  const [openMenu, setOpenMenu] = useState(false);

  return <>
    <Chip
      icon={icon}
      sx={{ '& .MuiChip-label': { mt: '2px' } }}
      label={`${label}: ${selected ? options?.find((menu) => menu.value === selected)?.name : "None"} `}
      variant={selected ? 'filled' : 'outlined'}
      onClick={(event) => setOpenMenu(event.currentTarget)}
    />
    <StyledMenu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      getContentAnchorEl={null}
      open={Boolean(openMenu)}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      onClose={() => setOpenMenu(null)}
    >
      <List dense>
        {options?.map((option) => {
          const isSelected = selected === option.value;
          return (
            <ListItemButton key={option} onClick={() => handleSelect(option.value)}>
              <ListItemIcon>{isSelected ? <Check /> : null}</ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItemButton>
          );
        })}
      </List>
    </StyledMenu>
  </>

}

export default SingleSelectMenu;