import { useState } from 'react';
import times from 'lodash/times';
import ReactQuill from 'react-quill';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Grid from "@mui/material/Grid";
import parseISO from 'date-fns/parseISO';
import Alert from '@mui/material/Alert';
import { Virtuoso } from 'react-virtuoso';
import Divider from '@mui/material/Divider'
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import { selectCurrentUser } from 'features/auth';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';
import { formatTime } from '@blackhyve/utilities/dates';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import LinearProgress from '@mui/material/LinearProgress';
import RichTextEditor from 'components/common/v3/RichTextEditor';
import AvatarComponent from 'components/common/v3/AvatarComponent';
import CommentMenuOptions from 'features/comments/components/CommentMenuOption';
import { useCreateCommentMutation, useGetCommentsQuery } from 'features/comments/store/comment.api';



const toolbarOptions = [
  ['bold', 'underline', 'strike', 'blockquote', 'image', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [],
];


/**
 * Comment Tab
 * @returns 
 */
const CommentTab = ({ entityId, entityType, entityTag }) => {

  const [comment, setComment] = useState('');
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const [createComment, { isLoading: isCommentGettingLoaded }] = useCreateCommentMutation();

  const { data: listOfComments = [], isLoading: isLoadingComments, isFetching: isFetchingComments, } =
    useGetCommentsQuery({ entityId, entityType, order_by: 'created_at,asc;' });


  const handleOnChangeComment = (newValue) => setComment(newValue);
  const handleCreateComment = () => {
    const newCommentObj = {
      entityType,
      entityId,
      entityTag,
      comment
    }
    createComment(newCommentObj)
      .then(() => {
        setComment('');
      })
  }

  const containerHeight = listOfComments?.length * 100;
  console.log('containerHeight =>', containerHeight)

  return <Grid container gap={1} pt={0}>
    {isFetchingComments && !isLoadingComments && <LinearProgress sx={{ width: '100%' }} />}
    <Grid container item sx={{ height: containerHeight ? `${containerHeight}px` : 'auto', maxHeight: 'calc(100vh - 305px)', overflowY: 'auto' }} xs={12}>
      <List sx={{ width: '100%' }}>
        {isLoadingComments && times(5, (row) => <CommentItemSkeleton key={row} />)}
        {listOfComments?.length ? <Virtuoso
          data={listOfComments}
          style={{ height: '100%', width: '100%', opacity: isFetchingComments ? 0.3 : 1 }}
          itemContent={(index) => {
            const comment = listOfComments[index];
            return <CommentItem comment={comment} key={comment?.id} />
          }}
        /> :
          <ListItem>
            <ListItemText sx={{ textAlign: 'center' }}>
              <Alert severity='info'>No Comments Added</Alert>
            </ListItemText>
          </ListItem>}
      </List>
    </Grid>
    <Grid container item px={2} spacing={2} xs={12}>
      <Grid item xs='auto'>
        <AvatarComponent
          name={`${currentUser?.first_name} ${currentUser.last_name}`}
          src={currentUser?.profile_image?.thumb_url}
        />
      </Grid>
      <Grid item xs>
        <RichTextEditor
          className="add-comment"
          handleOnChange={handleOnChangeComment}
          readOnly={false}
          text={comment}
          toolbarOptions={toolbarOptions}
        />
        <LoadingButton loading={isCommentGettingLoaded} sx={{ mt: 1, ml: 'auto' }} variant="contained" onClick={handleCreateComment}>
          Add Comment
        </LoadingButton>
      </Grid>
    </Grid>
  </Grid >

}

/**
 * Comment Item
 * @param {object} comment 
 * @param {number} comment.id
 * @param {string} comment.content
 * @param {[object]} comment.comments
 * @returns 
 */
const CommentItem = ({ comment }) => {

  const dateTime = formatTime(parseISO(comment?.created_at));
  const user = comment?.user;
  const isUserArchived = user?.deleted_at;

  return <>
    <ListItem alignItems='flex-start'>
      <ListItemAvatar>
        <AvatarComponent
          name={`${user?.first_name} ${user?.last_name}`}
          src={user?.profile_image?.thumb_url}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box alignItems={'center'} display={'flex'} gap={1}>
            <Typography sx={isUserArchived ? { fontWeight: 'bold', textDecoration: 'line-through' } : { fontWeight: 'bold', }}>
              {user?.first_name ? user?.first_name : 'Deleted User'} {user?.last_name}
              {' '}
            </Typography>
            <Typography
              color="text.secondary"
              component="span"
              sx={{ display: 'inline' }}
              variant="body2"
            >
              {dateTime}
            </Typography>
            <Box ml="auto">
              <CommentMenuOptions comment={comment} />
            </Box>
          </Box>
        }
        secondary={<ReactQuill
          bounds="quill"
          className={'comment'}
          readOnly={true}
          theme={'bubble'}
          value={comment?.comment}
        />
        }
      />
    </ListItem >
    <Divider component="li" variant="inset" />
  </>

}

const CommentItemSkeleton = () => {
  return <>
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Skeleton height={40} variant="circular" width={40} />
      </ListItemAvatar>
      <ListItemText
        primary={<Skeleton variant="text" width="60%" />}
        secondary={
          <>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </>
        }
      />
    </ListItem>
    <Divider />
  </>
}


export default CommentTab;