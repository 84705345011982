import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import ganttStore from '../../ganttConfig/ganttStore';
import { MenuButton } from 'assets/style-components/button';
import { parseDate } from '@blackhyve/utilities/dates';
import { List, ListItem, ListItemText, ListSubheader, Popover } from '@mui/material';
import { endOfWeek, format, isAfter, isSameDay, startOfWeek, subWeeks } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { registerMenuState, updateMenuState } from 'slices/customViewSlice';
import { Label as LabelComponent } from 'features/labels/components/Label';
import {
  jobwalkIconString,
  stuckPointString,
  warningIconString,
} from '../../ganttConfig/column/columnConfig';
import { useModalProvider } from 'components/common/ModalProvider';
import { useOnTaskClick } from 'hooks/useOnTaskClick';

const procurementRiskIconString = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="shopping_cart_24px"><path id="icon/action/shopping_cart_24px" fill-rule="evenodd" clip-rule="evenodd" d="M12.1975 7.98001C11.9708 8.39334 11.5308 8.66668 11.0308 8.66668H6.06414L5.33081 10H13.3308V11.3333H5.33081C4.31748 11.3333 3.67748 10.2467 4.16414 9.35334L5.06414 7.72668L2.66414 2.66668H1.33081V1.33334H3.51081L4.13748 2.66668H14.0041C14.5108 2.66668 14.8308 3.21334 14.5841 3.65334L12.1975 7.98001ZM12.8708 4.00001H4.77081L6.35081 7.33334H11.0308L12.8708 4.00001ZM5.33081 12C4.59748 12 4.00414 12.6 4.00414 13.3333C4.00414 14.0667 4.59748 14.6667 5.33081 14.6667C6.06414 14.6667 6.66414 14.0667 6.66414 13.3333C6.66414 12.6 6.06414 12 5.33081 12ZM10.6708 13.3333C10.6708 12.6 11.2641 12 11.9975 12C12.7308 12 13.3308 12.6 13.3308 13.3333C13.3308 14.0667 12.7308 14.6667 11.9975 14.6667C11.2641 14.6667 10.6708 14.0667 10.6708 13.3333Z" fill="red"/></g></svg>`


const useStyles = makeStyles(() => ({
  root: {
    color: '#5F6368',
    '&.Mui-disabled': {
      opacity: 'unset',
      paddingBottom: 'unset',
      '&:not(first-child)': {
        marginTop: '4px',
      },
      '& p': {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#c4c4c4',
      },
    },
  },
}));

const initialState = {
  missingDependencies: {
    displayText: 'Missing Dependencies',
    enabled: false,
    render: (task) => {
      if ((!task.$source.length || !task.$target.length) && task.type === 'task') {
        return `<span style="display: flex; align-items: center;" class="link-missing-warning">${warningIconString}</span>`;
      } else {
        return '';
      }
    },
  },
  jobwalkOpen: {
    displayText: 'Jobwalk Open',
    enabled: false,
    render: (task, gantt) => {
      // A jobwalk is due if it has not been jobwalked this week and over due if it hasn't been jobwalked in over a week.
      const today = new Date();
      const endOfThisWeek = endOfWeek(today, { weekStartsOn: 1 });
      const startOfThisWeek = startOfWeek(today, { weekStartsOn: 1 });
      const latestJobwalk = task.latest_job_walk ? parseDate(task.latest_job_walk) : 0;

      if (
        task.type === 'task' &&
        task.status !== 'complete' &&
        +task.forecasted_start_date < +endOfThisWeek &&
        +latestJobwalk < +startOfThisWeek
      ) {
        const pastDue =
          +task.forecasted_start_date < +startOfThisWeek &&
          +latestJobwalk < +subWeeks(startOfThisWeek, 1);

        const iconClass = pastDue ? 'jobwalk-past-due-icon' : 'jobwalk-due-icon';
        return `<span style="display: flex; align-items: center;" class="${iconClass}">${jobwalkIconString}</span>`;
      } else {
        return '';
      }
    },
  },
  openStuckPoints: {
    displayText: (
      <>
        Open <LabelComponent labelKey="stuck_point" plural={true} />
      </>
    ),
    enabled: false,
    render: (task) => {
      if (task?.open_stuck_points_count) {
        return `<span class="open-stuck-points" style="display: flex; align-items: center; height: 18px; background-color: red; border-radius: 2px; padding-left: 1px; padding-right: 1px; color: white; margin: auto;" data-id="${task?.id}">${stuckPointString} <span style="margin-left: 2px; margin-right: 2px; font-size: 12px;"> ${task.open_stuck_points_count} </span></span>`;
      } else {
        return '';
      }
    },
  },
  procurements: {
    displayText: 'Procurements At Risk',
    enabled: false,
    render: (task) => {
      if (task?.procurements?.length) {
        const startDate = task.forecasted_start_date || task.start_date;
        const procurementAtRisk = task.procurements.filter((procurement) => {
          const arrivalDate = procurement.arrival_date ? parseDate(procurement.arrival_date) : null;
          return arrivalDate && (isAfter(arrivalDate, startDate) || isSameDay(arrivalDate, startDate));
        });

        return procurementAtRisk.length
          ? `<span class="open-procurements" data-id="${task?.id}" 
              style="display: flex; align-items: center;">${procurementRiskIconString}</span>`
          : '';
      } else {
        return '';
      }
    }
  }
};

const options = {};
Object.keys(initialState).forEach((key) => {
  if (initialState[key].enabled !== undefined) {
    options[key] = initialState[key].enabled;
  }
});

const ToolsMenu = ({ ganttId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gantt = ganttStore.getGantt(ganttId);
  const selectedOptions = useSelector((state) => state.customViews?.menus?.tools?.currentValue);

  const [anchorEl, setAnchorEl] = useState(null);

  const { openModal } = useModalProvider();

  const openStuckPointDialog = useCallback(
    (task) => {
      openModal('stuckPointDialog', {
        open: true,
        ganttId: task.ganttId,
        taskId: task.id,
        projectId: task.project_id,
      });
    },
    [openModal]
  );

  const openProcurementDialog = useCallback(
    (task) => {
      openModal('procurementDialog', {
        open: true,
        taskId: task?.id,
        ganttId: task?.ganttId,
        startDate: task?.forecasted_start_date || task?.start_date,
        projectId: task?.project_id,
      });
    },
    [openModal]
  );

  useOnTaskClick({
    callback: openStuckPointDialog,
    className: '.open-stuck-points',
    gantt: gantt,
  });

  useOnTaskClick({
    callback: openProcurementDialog,
    className: '.open-procurements',
    gantt: gantt,
  });

  useEffect(() => {
    if (gantt && selectedOptions) {
      const tools = Object.assign({ ...selectedOptions }, initialState);
      Object.entries(selectedOptions).forEach(([key, value]) => {
        if (!value) {
          delete tools[key];
        }
      });
      gantt.config.tools = tools;
      gantt.render();
    }
  }, [selectedOptions, gantt, ganttId]);

  const handleToggleTools = (event, optionKey) => {
    dispatch(
      updateMenuState({
        name: 'tools',
        value: { ...selectedOptions, [optionKey]: !selectedOptions[optionKey] },
      })
    );
  };

  useEffect(() => {
    dispatch(
      registerMenuState({
        name: 'tools',
        value: options,
      })
    );
  }, [dispatch]);

  return (
    <>
      <MenuButton
        endIcon={Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
        isHighlighted={!isEqual(options, selectedOptions)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Tools
      </MenuButton>
      {anchorEl !== null ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={() => setAnchorEl(null)}
        >
          <List dense>
            {Object.keys(initialState).map((optionKey) => {
              const option = initialState[optionKey];
              const isEnabled = selectedOptions[optionKey];
              return (
                <>
                  {option.groupTitle ? (
                    <ListSubheader sx={{ fontWeight: 'bold' }}>{option.displayText}</ListSubheader>
                  ) : (
                    <ListItem
                      key={option.displayText}
                      secondaryAction={
                        !option.groupTitle ? (
                          <Switch
                            checked={isEnabled}
                            color="primary"
                            edge="end"
                            size="small"
                            onClick={(event) => handleToggleTools(event, optionKey)}
                          />
                        ) : (
                          ''
                        )
                      }
                    >
                      <ListItemText primary={option.displayText} />
                    </ListItem>
                  )}
                </>
              );
            })}
          </List>
        </Popover>
      ) : null}
    </>
  );
};

export default ToolsMenu;
