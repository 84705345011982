import { StyledDialog } from '@blackhyve/common';
import { parseDate } from '@blackhyve/utilities/dates';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { TableContainer } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Table } from 'components/table/Table';
import differenceInDays from 'date-fns/differenceInDays';
import { useGetCompaniesQuery } from 'features/companies/api/companies.api';
import { useState } from 'react';
import StuckPointDetails from './StuckPointDetails';
import StuckPointMoreOptions from './StuckPointMoreOptions';
import { riskExposureColorEnum, riskExposureEnum } from '../StuckPointEnums';
import { useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';

const daysStuckCalculator = (opened_on, is_resolved, resolved_on) => {
  if (!opened_on) return null;
  const daysStuck = is_resolved
    ? differenceInDays(parseDate(resolved_on), parseDate(opened_on))
    : differenceInDays(new Date(), opened_on);
  return daysStuck;
};

export const StuckPointTableColumns = {
  location_name: {
    label: 'Location',
    field: 'task.location_name',
    sort: true,
    comparator: () => (_aValue, _bValue, _order, a, b) => {
      if (!a.task.location_name) return 1;
      if (!b.task.location_name) return -1;
      return a.task.location_name.localeCompare(b.task.location_name);
    },
    render: ({ task }) => {
      return task?.location_name;
    },
  },
  zone_name: {
    label: 'Zone',
    field: 'task.zone_name',
    sort: true,
    comparator: () => (_aValue, _bValue, _order, a, b) => {
      if (!a.task.zone_name) return 1;
      if (!b.task.zone_name) return -1;
      return a.task.zone_name.localeCompare(b.task.zone_name);
    },
    render: ({ task }) => {
      return task?.zone_name;
    },
  },
  area_name: {
    label: 'Area',
    field: 'task.area_name',
    sort: true,
    comparator: () => (_aValue, _bValue, _order, a, b) => {
      if (!a.task.area_name) return 1;
      if (!b.task.area_name) return -1;
      return a.task.area_name.localeCompare(b.task.area_name);
    },
    render: ({ task }) => {
      return task?.area_name;
    },
  },
  title: {
    label: 'Title',
    field: 'title',
    sort: true,
    comparator: () => (_aValue, _bValue, _order, a, b) => {
      return a.title.localeCompare(b.title);
    },
  },
  task_name: {
    label: 'Task Name',
    field: 'task_name',
    sort: true,
    comparator: () => (_aValue, _bValue, _order, a, b) => {
      return a.task.name.localeCompare(b.task.name);
    },
    render: ({ task }) => {
      return task?.name;
    },
  },
  project: {
    label: 'Project Name',
    field: 'project',
    sort: true,
    render: ({ project }) => {
      return project?.name;
    },
  },
  opened_on: {
    label: 'Date Opened',
    field: 'opened_on',
    sort: true,
    comparator: () => (a, b, direction) => {
      return parseDate(a) - parseDate(b);
    },
    render: ({ opened_on }) => {
      return parseDate(opened_on).toLocaleDateString();
    },
  },
  resolved_on: {
    label: 'Date Resolved',
    field: 'resolved_on',
    sort: true,
    comparator: () => (a, b, direction) => {
      return parseDate(a) - parseDate(b);
    },
    render: ({ resolved_on }) => {
      return resolved_on && parseDate(resolved_on).toLocaleDateString();
    },
  },
  days_stuck: {
    label: 'Days Stuck',
    field: 'days_stuck',
    sort: true,
    comparator: () => (_aValue, _bValue, _order, a, b) => {
      const daysStuckA = daysStuckCalculator(a?.opened_on, a?.is_resolved, a?.resolved_on);
      const daysStuckB = daysStuckCalculator(b?.opened_on, b?.is_resolved, b?.resolved_on);
      return daysStuckA - daysStuckB;
    },
    render: ({ opened_on, is_resolved, resolved_on }) => {
      const daysStuck = daysStuckCalculator(opened_on, is_resolved, resolved_on);
      return daysStuck;
    },
  },
  responsible_users: {
    label: 'Responsible Users',
    field: 'responsible_users',
    sort: true,
    render: ({ responsible_users, project_id }) => {
      return <ResponsibleUser projectId={project_id} userIds={responsible_users} />;
    },
  },
  stuck_by: {
    label: 'Stuck By',
    field: 'stuck_by',
    sort: false,
    render: ({ companies_to_blame }) => {
      return <CompaniesToBlame companyIds={companies_to_blame} />;
    },
  },
  risk_exposure: {
    label: 'Risk Exposure',
    field: 'risk_exposure',
    sort: true,
    comparator: () => (_aValue, _bValue, _order, a, b) => {
      const riskExposureOrder = {
        low: 1,
        medium: 2,
        high: 3,
      };

      return riskExposureOrder[a.risk_exposure] - riskExposureOrder[b.risk_exposure];
    },
    render: ({ risk_exposure }) => {
      return (
        <Chip
          color={riskExposureColorEnum[risk_exposure]}
          label={riskExposureEnum[risk_exposure]}
          sx={{ textTransform: 'capitalize' }}
        />
      );
    },
  },
  comments: {
    label: 'Comments',
    field: 'comments',
    render: ({ comments_count }) => {
      return (
        <Stack direction={'row'} spacing={1}>
          <ForumOutlinedIcon color={comments_count > 0 ? 'info' : ''} />
          <Typography>{comments_count}</Typography>
        </Stack>
      );
    },
  },
  status: {
    label: 'Status',
    field: 'status',
    sort: true,
    render: ({ is_resolved }) =>
      is_resolved ? (
        <Chip color="success" label="Resolved" variant="outlined" />
      ) : (
        <Chip color="error" label="Pending" variant="outlined" />
      )
  },
  moreOptions: {
    field: 'moreOptions',
    cellProps: { align: 'right', onClick: (e) => e.stopPropagation() },
    render: (stuckPoint, meta) => <StuckPointMoreOptions stuckPoint={stuckPoint} />
  }
}

const defaultColumns = Object.values(StuckPointTableColumns)
  .filter((column) => column.field !== "project")


/**
 * 
 * @param {[object]} stuckPoints
 * @param {boolean} isFetching 
 * @param {boolean} isLoading
 * @param {[string]} columns 
 * @returns 
 */
const StuckPointTable = ({ stuckPoints, isFetching, isLoading, columns = defaultColumns }) => {
  const [openStuckPointDetails, setOpenStuckPointDetails] = useState(false);
  const projectId = openStuckPointDetails
    ? stuckPoints.find((stuckPoint) => stuckPoint.id === openStuckPointDetails)?.project_id
    : undefined;

  if (openStuckPointDetails && !projectId) {
    setOpenStuckPointDetails(false);
  }

  return (
    <TableContainer>
      <Table
        columns={columns}
        defaultOrderBy={false}
        isFetching={isFetching}
        isLoading={isLoading}
        rows={stuckPoints}
        stickyHeader={true}
        rowProps={(row) => ({
          onClick: (e) => {
            setOpenStuckPointDetails(row.id);
          },
          sx: { cursor: 'pointer' },
        })}
      />
      <StyledDialog
        handleClose={() => setOpenStuckPointDetails(undefined)}
        maxWidth={'md'}
        open={openStuckPointDetails && projectId}
        scroll={'body'}
        title={''}
      >
        <StuckPointDetails id={openStuckPointDetails} projectId={projectId} />
      </StyledDialog>
    </TableContainer>
  );
};

/**
 * Companies to blame
 * @param {[string]} companyIds
 * @returns
 */
export const CompaniesToBlame = ({ companyIds }) => {
  const { data: companies = [] } = useGetCompaniesQuery();

  const companyDetails = companyIds
    ?.map((companyId) => {
      return companies.find((company) => company.id === companyId);
    })
    .filter((company) => company);

  return companyDetails.map((company, index) => {
    return (
      <Typography key={company.id} variant="subtitle2">
        {company.name}
        {companyDetails.length > index + 1 ? ', ' : ''}
      </Typography>
    );
  });
};


/**
 * Responsible User
 * @param {[string]} companyIds
 * @returns
 */
export const ResponsibleUser = ({ userIds, projectId }) => {
  const { data: responsibleUsers = { users: [] } } = useGetWorkspaceCompanyQuery(
    { projectId }
  );
  const userDetails = userIds
    ?.map((userId) => {
      return responsibleUsers?.users?.find((user) => user.id === userId);
    }).filter((user) => user);

  return <Box display="inline">
    {userDetails?.map((user, index) => {
      return (
        <Typography component="span" key={user.id} variant="subtitle2">
          {user.first_name} {user.last_name}
          {userDetails.length > index + 1 ? ', ' : ''}
        </Typography>
      );
    })}
  </Box>
};

export default StuckPointTable;
