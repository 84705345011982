import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Table } from "components/table/Table";
import TableContainer from "@mui/material/TableContainer";
import ProcurementIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useGetTaskProcurementsQuery } from 'features/procurement/store/procurement.api';
import { ProcurementTableColumns } from 'features/procurement/components/ProcurementTable';
import { useNavigate, useParams } from 'react-router-dom';

const columns = Object.values(ProcurementTableColumns)

/**
 * Procurement Task Dialog
 * @param {*} taskId 
 * @returns 
 */
const ProcurementTab = ({ taskId }) => {

  const navigate = useNavigate();
  const projectId = Number(useParams().projectId) || undefined;
  const workspaceId = Number(useParams().workspaceId) || undefined;

  const { data: taskProcurements = [], isLoading, isFetching } =
    useGetTaskProcurementsQuery(taskId);

  const handleRedirectToDetailsPage = (row) => {
    navigate(
      `/workspace/${workspaceId}/projects/${projectId}/procurements/${row?.id}`
    );
  }

  return <Stack spacing={1}>
    <Stack alignItems={'center'} direction={'row'}>
      <ProcurementIcon color={'action'} sx={{ p: 1.5, fontSize: '1.75rem' }} />
      <Typography sx={{ fontWeight: '600' }} variant={'h6'}>
        Procurements
      </Typography>
    </Stack>
    <Paper elevation={3}>
      <TableContainer sx={{ maxHeight: '500px' }}>
        <Table
          columns={columns}
          defaultOrderBy={false}
          isFetching={isFetching}
          isLoading={isLoading}
          rows={taskProcurements}
          size="small"
          stickyHeader={true}
          rowProps={(row) => ({
            onClick: (e) => {
              handleRedirectToDetailsPage(row)
            },
            sx: { cursor: 'pointer' },
          })}
        />
      </TableContainer>
    </Paper>
  </Stack>



}

export default ProcurementTab;