import { useState } from 'react';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import ProcurementDetail from '../components/ProcurementDetail';
import { ContentLayout } from 'components/layouts/ContentLayout';
import { useGetProcurementsQuery } from '../store/procurement.api';
import ProcurementFormDialog from '../components/ProcurementFormDialog';
import ProcurementDataGridTable from '../components/ProcurementDataGridTable';

const Procurement = () => {
  const projectId = Number(useParams().projectId) || undefined;
  const [openCreateProcurementDialog, setOpenCreateProcurementDialog] = useState(false);

  const handleCloseAddProcurement = () => setOpenCreateProcurementDialog(false);

  const [filters, setFilters] = useState({
    status: null,
    page: 1,
    per_page: 50,
    order_by: 'created_at,desc',
  });

  const {
    data: { data: procurementList = [], meta: { total = 0 } = {} } = {},
    isLoading,
    isFetching,
  } = useGetProcurementsQuery({
    projectId,
    ...pickBy(filters, identity),
  });

  return (
    <ContentLayout>
      <Container maxWidth={'xxl'} sx={{ mt: 2 }}>
        <ProcurementDataGridTable
          filters={filters}
          isFetching={isFetching}
          isLoading={isLoading}
          rows={procurementList}
          setFilters={setFilters}
          total={total}
        />
      </Container>
      {openCreateProcurementDialog && (
        <ProcurementFormDialog
          handleClose={handleCloseAddProcurement}
          open={true}
          projectId={projectId}
        />
      )}
    </ContentLayout>
  );
};

export const procurementRoutes = [
  { path: '', element: <Procurement /> },
  { path: ':id', element: <ProcurementDetail /> },
];
