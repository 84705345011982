import { gantt } from '@blackhyve/dhtmlx-gantt';
import { parseDate } from '@blackhyve/utilities/dates';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Avatar from 'components/common/v3/Avatar';
import {
  addDays,
  addWeeks,
  endOfDay,
  isAfter,
  isBefore,
  isPast,
  isSameDay,
  isWithinInterval,
  parse,
  startOfDay,
  startOfWeek,
  subDays,
  subWeeks,
} from 'date-fns';
import { useGetProjectsQuery } from 'features/projects/store/project.api';
import { flatMap, uniqBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import { useSelector } from 'react-redux';
import store from 'store';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';

export const companiesFilter = {
  name: 'Companies',
  filterFunction: (task, filterValue) => {
    if (!filterValue?.length && (!task.companies || !task.companies.length)) {
      return true;
    } else if (!filterValue?.length && task?.companies.length) {
      return false;
    }

    if (filterValue.filter((value) => value.id === -1)?.length && !task?.companies?.length) {
      return true;
    }
    // const filterCompanyValues = flatten(
    //   filterValue.map((company) => {
    //     return company?.companies ? company.companies : [];
    //   })
    // );
    return task.companies?.some((company) =>
      filterValue.some((selectedCompany) => selectedCompany.id === company.id)
    );
  },
  render: ({ handleUpdate, id, value, projectId, gantt }) => {
    const companyList = uniqBy(flatMap(gantt.getTaskByTime(), 'companies'), 'id').filter(
      (company) => Boolean(company)
    );

    return (
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        multiple
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={[...companyList, { id: -1, name: 'No company assigned' }]}
        size="small"
        value={value ? value : []}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Companies"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          const [firstName, lastName] = option?.name.split(' ');
          const icon = option?.logo?.thumb_url;
          return (
            <ListItemButton {...props}>
              <ListItemAvatar>
                <Avatar
                  avatarStyle={{ height: 30, width: 30, fontSize: 15 }}
                  firstName={firstName}
                  icon={icon}
                  lastName={lastName}
                />
              </ListItemAvatar>
              <ListItemText primary={option.name} />
              <Checkbox
                checked={selected}
                checkedIcon={<CheckBoxIcon fontSize="medium" />}
                edge={'end'}
                icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
              />
            </ListItemButton>
          );
        }}
        renderTags={(options) => {
          const avatarStyle = { aspectRatio: 1, height: '24px', width: 'auto', fontSize: 11 };
          return options
            ? options.map((option) => {
              const [firstName, lastName] = option?.name.split(' ');
              const icon = option?.logo?.thumb_url;
              return (
                <Avatar
                  avatarStyle={avatarStyle}
                  firstName={firstName}
                  icon={icon}
                  lastName={lastName}
                />
              );
            })
            : null;
        }}
        onChange={(_, newValue) => handleUpdate({ id, value: newValue })}
      />
    );
  },
};

export const responsibleUserFilter = {
  name: 'Responsible Users',
  filterFunction: (task, filterValue) => {
    if (!filterValue?.length && (!task.responsible_users || !task.responsible_users.length)) {
      return true;
    } else if (!filterValue?.length && task?.users.length) {
      return false;
    }

    if (
      filterValue.filter((value) => value.id === -1)?.length &&
      !task?.responsible_users?.length
    ) {
      return true;
    }

    return task.responsible_users?.some((user) =>
      filterValue.some((selectedUser) => selectedUser.id === user.id)
    );
  },
  render: ({ handleUpdate, id, value, projectId, gantt }) => {
    const usersList = uniqBy(flatMap(gantt.getTaskByTime(), 'responsible_users'), 'id').filter(
      (user) => Boolean(user)
    );

    return (
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        multiple
        getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={[...usersList, { id: -1, first_name: 'No Users', last_name: 'Assigned' }]}
        size="small"
        value={value ? value : []}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Users"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(props, option, { selected }) => {
          const icon = option?.profile_image?.thumb_url;
          return (
            <ListItemButton {...props}>
              <ListItemAvatar>
                <Avatar
                  avatarStyle={{ height: 30, width: 30, fontSize: 15 }}
                  firstName={option.first_name}
                  icon={icon}
                  lastName={option.last_name}
                />
              </ListItemAvatar>
              <ListItemText primary={option?.first_name + ' ' + option?.last_name} />
              <Checkbox
                checked={selected}
                checkedIcon={<CheckBoxIcon fontSize="medium" />}
                edge={'end'}
                icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
              />
            </ListItemButton>
          );
        }}
        renderTags={(options) => {
          const avatarStyle = { aspectRatio: 1, height: '24px', width: 'auto', fontSize: 11 };
          return options
            ? options.map((option) => {
              const icon = option?.logo?.thumb_url;
              return (
                <Avatar
                  avatarStyle={avatarStyle}
                  firstName={option.first_name}
                  icon={icon}
                  lastName={option.last_name}
                />
              );
            })
            : null;
        }}
        onChange={(_, newValue) => handleUpdate({ id, value: newValue })}
      />
    );
  },
};

export const contactFilter = {
  name: 'Contacts',
  filterFunction: (task, filterValue) => {
    if (!filterValue?.length && (!task.contacts || !task.contacts.length)) {
      return true;
    } else if (!filterValue?.length && task?.contacts.length) {
      return false;
    }

    if (filterValue.filter((value) => value.id === -1)?.length && !task?.contacts?.length) {
      return true;
    }

    return task.contacts?.some((contact) =>
      filterValue.some((selectedContact) => selectedContact.id === contact.id)
    );
  },
  render: ({ handleUpdate, id, value, projectId, gantt }) => {
    const contactList = uniqBy(flatMap(gantt.getTaskByTime(), 'contacts'), 'id').filter((company) =>
      Boolean(company)
    );

    return (
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        multiple
        getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
        getOptionSelected={(option, value) => option.id === value.id}
        options={[...contactList, { id: -1, first_name: 'No Contacts', last_name: 'Assigned' }]}
        size="small"
        value={value ? value : []}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder="Select Contacts"
            size="small"
            variant="outlined"
          />
        )}
        renderOption={(option, { selected }) => {
          const icon = option?.logo?.thumb_url;
          return (
            <>
              <ListItemAvatar>
                <Avatar
                  avatarStyle={{ height: 30, width: 30, fontSize: 15 }}
                  firstName={option.first_name}
                  icon={icon}
                  lastName={option.lastName}
                />
              </ListItemAvatar>
              <ListItemText primary={option?.first_name + ' ' + option?.last_name} />
              <Checkbox
                checked={selected}
                checkedIcon={<CheckBoxIcon fontSize="medium" />}
                edge={'end'}
                icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
              />
            </>
          );
        }}
        renderTags={(options) => {
          const avatarStyle = { aspectRatio: 1, height: '24px', width: 'auto', fontSize: 11 };
          return options
            ? options.map((option) => {
              const icon = option?.logo?.thumb_url;
              return (
                <Avatar
                  avatarStyle={avatarStyle}
                  firstName={option.first_name}
                  icon={icon}
                  lastName={option.last_name}
                />
              );
            })
            : null;
        }}
        onChange={(_, newValue) => handleUpdate({ id, value: newValue })}
      />
    );
  },
};

export const statusFilter = {
  name: 'Status',
  filterFunction: (task, filterValue) => compareFunction(task, filterValue, 'status'),
  render: ({ handleUpdate, id, value }) => {
    const statusOptions = [
      { id: 'active', name: 'Active' },
      { id: 'todo', name: 'Todo' },
      { id: 'complete', name: 'Complete' },
      { id: 'blocked', name: 'Blocked' },
    ];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        options={statusOptions}
        value={value}
      />
    );
  },
};

export const locationFilter = {
  name: 'Location',
  filterFunction: (task, filterValue) => compareFunction(task, filterValue, 'location_id'),
  render: ({ handleUpdate, id, value, gantt }) => {
    const lbsData = gantt?.constants?.lbsData;
    const locationOptions = !isEmpty(lbsData?.locations) ? Object.values(lbsData.locations) : [];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        options={locationOptions}
        value={value}
      />
    );
  },
};
export const zoneFilter = {
  name: 'Zone',
  filterFunction: (task, filterValue) => compareFunction(task, filterValue, 'zone_id'),
  render: ({ handleUpdate, id, value, gantt }) => {
    const lbsData = gantt?.constants?.lbsData;
    const zoneOptions = !isEmpty(lbsData?.zones) ? Object.values(lbsData.zones) : [];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        options={zoneOptions}
        value={value}
      />
    );
  },
};
export const areaFilter = {
  name: 'Area',
  filterFunction: (task, filterValue) => compareFunction(task, filterValue, 'area_id'),
  render: ({ handleUpdate, id, value, gantt }) => {
    const lbsData = gantt?.constants?.lbsData;
    const areaOptions = !isEmpty(lbsData?.areas) ? Object.values(lbsData.areas) : [];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        options={areaOptions}
        value={value}
      />
    );
  },
};

export const timeFrameFilter = {
  name: 'TimeFrame',
  filterFunction: (task, filterValue) => {
    let isValid = true;
    if (!filterValue) {
      return false;
    }
    if (task.id === -1) {
      return true;
    }
    if (task.$group_id || task.$virtual) {
      return true
    }

    const formatFunc = gantt.date.date_to_str('%Y-%m-%d');
    const taskStartDate = parse(formatFunc(task.forecasted_start_date), 'yyyy-MM-dd', new Date());
    const taskEndDate = parse(formatFunc(task.forecasted_end_date), 'yyyy-MM-dd', new Date());

    switch (filterValue.value) {
      case 1: {
        // All Dates
        isValid = true;
        break;
      }
      case 2: {
        // Past 7 Days & All Future
        const currentDate = new Date();
        const pastDate = subDays(currentDate, 7);
        isValid =
          (isPast(taskStartDate) && taskStartDate >= pastDate) ||
          isAfter(taskStartDate, currentDate) ||
          (isBefore(taskStartDate, currentDate) && isAfter(taskEndDate, pastDate));
        break;
      }
      case 3: {
        // Past 7 Days and Next 7 Days
        const currentDate = new Date();
        const pastDate = subDays(currentDate, 7);
        const futureDate = addDays(currentDate, 7);
        isValid =
          isWithinInterval(taskStartDate, { start: pastDate, end: futureDate }) ||
          isWithinInterval(taskEndDate, { start: pastDate, end: futureDate }) ||
          (isBefore(taskStartDate, pastDate) && isAfter(taskEndDate, futureDate));
        break;
      }
      case 4: {
        // Past 14 Days and Next 14 Days
        const currentDate = new Date();
        const pastDate = subDays(currentDate, 14);
        const futureDate = addDays(currentDate, 14);
        isValid =
          isWithinInterval(taskStartDate, { start: pastDate, end: futureDate }) ||
          isWithinInterval(taskEndDate, { start: pastDate, end: futureDate }) ||
          (isBefore(taskStartDate, pastDate) && isAfter(taskEndDate, futureDate));

        break;
      }
      case 5: {
        // Today
        const currentDate = new Date();
        isValid = isSameDay(taskStartDate, currentDate);
        break;
      }
      case 6: {
        // Today Onward
        const currentDate = new Date();
        isValid = isSameDay(taskStartDate, currentDate) || isAfter(taskStartDate, currentDate);
        break;
      }
      case 7: {
        // 4 Week lookahead
        const startDate = subWeeks(startOfWeek(new Date()), 1);
        const endDate = addWeeks(startDate, 4);

        isValid =
          isWithinInterval(taskStartDate, { start: startDate, end: endDate }) ||
          isWithinInterval(taskEndDate, { start: startDate, end: endDate }) ||
          (isBefore(taskStartDate, startDate) && isAfter(taskEndDate, endDate));
        break;
      }
      case 8: {
        // 6 Week lookahead
        // const nextWeek = addWeeks(startOfWeek(new Date()), 1);
        const startDate = subWeeks(startOfWeek(new Date()), 1);
        const endDate = addWeeks(startDate, 6);
        isValid =
          isWithinInterval(taskStartDate, { start: startDate, end: endDate }) ||
          isWithinInterval(taskEndDate, { start: startDate, end: endDate }) ||
          (isBefore(taskStartDate, startDate) && isAfter(taskEndDate, endDate));
        break;
      }
      default:
        break;
    }

    return isValid;
  },
  render: ({ handleUpdate, id, value }) => {
    const timeFrameOptions = [
      { name: 'All Dates', value: 1 },
      { name: 'Past 7 Days & All Future', value: 2 },
      { name: 'Past 7 Days and Next 7 Days', value: 3 },
      { name: 'Past 14 Days and Next 14 Days', value: 4 },
      { name: 'Today', value: 5 },
      { name: 'Today Onward', value: 6 },
      { name: '4 Week Lookahead', value: 7 },
      { name: '6 Week Lookahead', value: 8 },
    ];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        isMultiple={false}
        options={timeFrameOptions}
        value={value}
      />
    );
  },
};

export const customTimeFrameFilter = {
  name: 'Custom TimeFrame',
  filterFunction: (task, { startDate, endDate }) => {
    if (task.id === -1 || task.$group_id) {
      return true;
    }
    const formatFunc = gantt.date.date_to_str('%Y-%m-%d');
    const taskStartDate = parse(
      formatFunc(new Date(task.forecasted_start_date)),
      'yyyy-MM-dd',
      new Date()
    );
    const taskEndDate = parse(
      formatFunc(new Date(task.forecasted_end_date)),
      'yyyy-MM-dd',
      new Date()
    );

    const isWithinStartDateInterval = isWithinInterval(taskStartDate, {
      start: startDate,
      end: endDate,
    });
    const isWithinEndDateInterval = isWithinInterval(taskEndDate, {
      start: startDate,
      end: endDate,
    });
    const isSpanningInterval = isBefore(taskStartDate, startDate) && isAfter(taskEndDate, endDate);

    return isWithinStartDateInterval || isWithinEndDateInterval || isSpanningInterval;
  },
  render: ({ handleUpdate, id, value, gantt, error }) => {
    return (
      <CustomTimeFrameFilter
        error={error}
        gantt={gantt}
        handleUpdate={handleUpdate}
        id={id}
        value={value}
      />
    );
  },
  validate: (value) => {
    if (!value) {
      return true;
    }
    return false;
  },
};

export const projectFilter = {
  name: 'Project',
  filterFunction: (task, filterValue) => {
    return compareFunction(task, filterValue, 'project_id');
  },
  render: ({ handleUpdate, id, value }) => {
    const state = store.getState();
    const workspaceId = state.auth.workspaceId;

    const selectAllProjects = (list) => {
      return list || [];
    };

    return (
      <FilterValueDropdown
        dispatchFunc={() => useGetProjectsQuery({ workspaceId })}
        handleUpdate={handleUpdate}
        id={id}
        selectorFunc={selectAllProjects}
        value={value}
      />
    );
  },
};

export const dependencyWarningFilter = {
  name: 'Missing Dependencies',
  filterFunction: (task, filterValue) => {
    if (filterValue?.id) {
      return !task.$source.length || !task.$target.length;
    } else if (!filterValue?.id) {
      return task.$source.length && task.$target.length;
    }
  },
  render: ({ handleUpdate, id, value }) => {
    const options = [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        isMultiple={false}
        options={options}
        value={value}
      />
    );
  },
};

export const paceFilter = {
  name: 'Pace',
  filterFunction: (task, filterValue) => {
    switch (filterValue.id) {
      case 0:
        return task.pace === 0;
      case 1:
        return task.pace >= 1;
      case -1:
        return task.pace <= -1;
      default:
        return false;
    }
  },
  render: ({ handleUpdate, id, value }) => {
    const options = [
      { id: 0, name: 'On Target' },
      { id: 1, name: 'Ahead Of Target' },
      { id: -1, name: 'Behind Of Target' },
    ];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        isMultiple={false}
        options={options}
        value={value}
      />
    );
  },
};

export const tradeFilter = {
  name: 'Trade',
  filterFunction: (task, filterValue) => {
    if (!filterValue?.length && (!task?.trades || !task?.trades?.length)) {
      return true;
    } else if (!filterValue?.length && task?.trades?.length) {
      return false;
    }
    if (filterValue.includes(-1) && !task?.trades?.length) {
      return true;
    }
    return task.trades?.some((tradeId) => filterValue.includes(tradeId));
  },
  render: ({ handleUpdate, id, value }) => {
    const state = store.getState();
    const tradeEntities = state?.companies?.trades?.entities;
    const tradeOptions = !isEmpty(tradeEntities) ? Object.values(tradeEntities) : [];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        options={[{ id: -1, name: 'No trade assigned' }, ...tradeOptions]}
        value={value}
      />
    );
  },
};

export const jobwalkDueOpen = {
  name: 'Jobwalk',
  filterFunction: (task, filterValue) => {
    const today = new Date();
    const jobwalkDue =
      task.type === 'task' &&
      task.status !== 'complete' &&
      +task.forecasted_start_date < +endOfDay(today) &&
      (task.latest_job_walk === null || +parseDate(task.latest_job_walk) < +startOfDay(today));

    if (filterValue?.id) {
      return jobwalkDue;
    } else if (!filterValue?.id) {
      return !jobwalkDue;
    }
  },
  render: ({ handleUpdate, id, value }) => {
    const options = [
      { id: true, name: 'Open' },
      { id: false, name: 'Closed' },
    ];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        isMultiple={false}
        options={options}
        value={value}
      />
    );
  },
};

export const stuckPointFilters = {
  name: 'Stuck Points',
  labelKey: 'stuck_point',
  filterFunction: (task, filterValue) => {
    if (filterValue?.id) {
      return task.open_stuck_points_count > 0;
    } else if (!filterValue?.id) {
      return task.open_stuck_points_count === 0;
    }
  },
  render: ({ handleUpdate, id, value }) => {
    const options = [
      { id: true, name: 'Open' },
      { id: false, name: 'None' },
    ];
    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        isMultiple={false}
        options={options}
        value={value}
      />
    );
  },
};


export const tagFilter = {
  name: 'Tags',
  labelKey: 'tags',
  filterFunction: (task, filterValue) => {
    return filterValue?.length && task?.tags?.length
      ? task.tags.some((tag) => filterValue.includes(tag.id))
      : false;
  },
  render: ({ handleUpdate, id, value, gantt }) => {
    const tags = uniqBy(flatMap(gantt.getTaskByTime(), 'tags'), 'id')?.filter(
      (tag) => Boolean(tag)
    );
    const filteredTags = tags?.length ?
      tags.filter((tag) => tag.type === "standard" || tag.type === "cascading") : []

    return (
      <FilterValueDropdown
        handleUpdate={handleUpdate}
        id={id}
        options={filteredTags}
        value={value}
      />
    );
  },
};


export const smartTagFilter = {
  name: 'Smart Tags',
  labelKey: 'smartTags',
  filterFunction: (task, filterValue) => {
    if (!task?.tags?.length) {
      return false
    }
    const generatedTagDropdownValue = filterValue?.generatedTagDropdownValue;
    if (generatedTagDropdownValue?.smartTaskIds?.includes(task.id)) {
      return true
    }
    return task?.tags?.some((tag) =>
      generatedTagDropdownValue?.ids?.includes(tag.id))
  },
  render: ({ handleUpdate, id, value, error, gantt }) => {
    return (
      <CustomSmartTagFilter error={error} gantt={gantt} handleUpdate={handleUpdate} id={id} value={value} />
    );
  },
  validate: (value) => {
    const { smartKeyDropdownValue, generatedTagDropdownValue } = value || {};
    if (!smartKeyDropdownValue && !generatedTagDropdownValue) {
      return {
        smartKeyDropdownValue: true,
        generatedTagDropdownValue: true
      };
    }
    const errorObj = {
      ...(!smartKeyDropdownValue && { smartKeyDropdownValue: true }),
      ...(!generatedTagDropdownValue && { generatedTagDropdownValue: true }),
    };
    return Object.keys(errorObj)?.length ? errorObj : false;
  },
};

const compareFunction = (task, fieldValue, fieldName) => {
  if (
    (!fieldValue || (Array.isArray(fieldValue) && !fieldValue?.length)) &&
    (!task[fieldName] || (Array.isArray(task[fieldName]) && !task[fieldName]?.length))
  ) {
    return true;
  } else if (
    (!fieldValue || (Array.isArray(fieldValue) && !fieldValue?.length)) &&
    task[fieldName]
  ) {
    return false;
  }

  return fieldValue.includes(task[fieldName]);
};

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap !important;',
  },
  '& .Mui-focused': {
    flexWrap: 'wrap !important',
  },
  '& .Mui-focused .MuiAutocomplete-tag': { maxWidth: '100% !important' },
});

/**
 * Common dropdown
 * @param {Object} handleUpdate
 * @param {String} id
 * @param {String} value
 * @param {[Object]} options
 * @param {Object} renderTags
 * @param {Boolean} isMultiple
 * @returns
 */
export const FilterValueDropdown = ({
  handleUpdate,
  id,
  value,
  options,
  renderTags,
  isMultiple = true,
  dispatchFunc,
  selectorFunc,
  error,
  helperText
}) => {
  const { data, isLoading } = dispatchFunc ? dispatchFunc() : {};
  const reduxOptions = useSelector(selectorFunc ? () => selectorFunc(data) : () => []);
  return (
    <StyledAutocomplete
      autoComplete
      autoHighlight
      disableClearable
      disableCloseOnSelect={isMultiple ? true : false}
      isOptionEqualToValue={(option, value) => option.id === value}
      limitTags={3}
      loading={isLoading}
      multiple={isMultiple}
      options={options ? options : reduxOptions}
      renderTags={renderTags && renderTags}
      size="small"
      value={value ? value : isMultiple ? [] : null}
      variant="outlined"
      getOptionLabel={(option) => {
        return option?.name
          ? option.name
          : (selectorFunc ? reduxOptions : options).find(({ id }) => id === option)?.name;
      }}
      renderInput={(params) =>
        <TextField {...params}
          error={error}
          helperText={error ? helperText : ''}
          variant="outlined"
        />}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItemButton {...props}>
            <ListItemText>{option.name}</ListItemText>
            {isMultiple && (
              <ListItemIcon>
                <Checkbox
                  checked={selected}
                  checkedIcon={<CheckBoxIcon fontSize="medium" />}
                  edge={'end'}
                  icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                />
              </ListItemIcon>
            )}
          </ListItemButton>
        );
      }}
      onChange={(event, newValue) => {
        const value = isMultiple ? newValue.map((val) => (isObject(val) ? val.id : val)) : newValue;
        handleUpdate({ id, value });
      }}
    />
  );
};

const CustomTimeFrameFilter = ({ handleUpdate, id, value, error }) => {
  // const startDate = value ? typeof value.startDate === String ? new Date(value.startDate) : value.startDate : null;
  const startDate = value?.startDate instanceof Date
    ? value.startDate
    : value?.startDate
      ? new Date(value.startDate)
      : null;
  const endDate = value?.startDate instanceof Date
    ? value.endDate
    : value?.endDate
      ? new Date(value.endDate)
      : null;


  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
      <Grid item>
        <Typography component="label" variant="body1">
          Start:
        </Typography>
      </Grid>
      <Grid item xs>
        <DatePicker
          sx={{ width: '100%' }}
          value={startDate}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              error,
              helperText: error ? 'Please select both a start and end date' : null,
            },
          }}
          onChange={(newValue) => {
            handleUpdate({ id, value: { ...value, startDate: newValue } });
          }}
        />
      </Grid>
      <Grid item>
        <Typography component="label" variant="body1">
          End:
        </Typography>
      </Grid>
      <Grid item xs>
        <DatePicker
          minDate={startDate}
          sx={{ width: '100%' }}
          value={endDate}
          slotProps={{
            textField: {
              size: 'small',
              fullWidth: true,
              error,
              helperText: error ? 'Please select both a start and end date' : null,
            },
          }}
          onChange={(newValue) => {
            handleUpdate({ id, value: { ...value, endDate: newValue } });
          }}
        />
      </Grid>
    </Grid>
  );
};


const CustomSmartTagFilter = ({ handleUpdate, id, value = {}, error, gantt }) => {

  // const { data: tags = [] } = useGetTagsQuery();

  const tags = uniqBy(flatMap(gantt.getTaskByTime(), 'tags'), 'id')?.filter(
    (tag) => Boolean(tag)
  );

  const smartTags = tags?.filter((tag) => tag.type === "smart");
  const options = tags?.filter((tag) => tag.smart_tag_id === value?.smartKeyDropdownValue?.id);

  const groupByName = (arr) => {
    const groups = arr.reduce((acc, { id, name, smart_task_id }) => {
      if (!acc[name]) {
        acc[name] = { ids: [], smartTaskIds: [] };
      }
      acc[name].ids.push(id);
      acc[name].smartTaskIds.push(smart_task_id);
      return acc;
    }, {});

    return Object.entries(groups).map(([name, { ids, smartTaskIds }]) => ({
      name,
      ids,
      smartTaskIds,
    }));
  };

  const groupedData = groupByName(options);

  return <Grid container item alignItems={'start'} spacing={1} xs={12}>

    <Grid container item alignItems="center" spacing={1} xs={6}>
      <Grid item xs="auto">
        <Typography>Type</Typography>
      </Grid>
      <Grid item xs>
        <FilterValueDropdown
          error={error?.smartKeyDropdownValue}
          helperText="Type field can not be blank"
          id={id}
          isMultiple={false}
          options={smartTags}
          value={value?.smartKeyDropdownValue}
          handleUpdate={(updatedData) => {
            handleUpdate({
              id,
              value: {
                generatedTagDropdownValue: null,
                smartKeyDropdownValue: updatedData?.value
              }
            })
          }}
        />
      </Grid>
    </Grid>
    <Grid container item alignItems="center" spacing={1} xs={6}>
      <Grid item xs="auto">
        <Typography>Name</Typography>
      </Grid>
      <Grid item xs>
        <FilterValueDropdown
          error={error?.generatedTagDropdownValue}
          helperText="Name field can not be blank"
          id={id}
          isMultiple={false}
          options={groupedData}
          value={value?.generatedTagDropdownValue}
          handleUpdate={(updatedData) => {
            handleUpdate({
              id,
              value: {
                ...value,
                generatedTagDropdownValue: updatedData?.value
              }
            })
          }}
        />
      </Grid>
    </Grid>
  </Grid >

}