import Box from '@mui/material/Box';
import format from 'date-fns/format';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { parseDate } from '@blackhyve/utilities/dates';
import TagList from 'features/tags/components/TagList';
import ListItemButton from '@mui/material/ListItemButton';
import LinearProgress from '@mui/material/LinearProgress';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AvatarComponent from 'components/common/v3/AvatarComponent';
import { useGetProcurementTasksQuery } from '../store/procurement.api';
import { ProductionBoardSkeletonList } from 'features/productionBoard/components/ProductionBoardRow';

import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { OptionsMenu } from '@blackhyve/common';
import IconButton from '@mui/material/IconButton';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavigateNext from '@mui/icons-material/NavigateNext';
import GanttIcon from '@blackhyve/common/src/icons/GanttIcon';

const ProcurementTaskList = ({ procurementId }) => {

  const workspaceId = Number(useParams().workspaceId) || undefined;
  const { data: tasks, isLoading } = useGetProcurementTasksQuery({ id: procurementId })

  return <Grid container gap={1}>
    {isLoading ? <ProductionBoardSkeletonList isChildrenList={true} /> :
      tasks?.map((task) => {
        return <Task key={task.id} task={task} workspaceId={workspaceId} />
      })}
  </Grid>
}

export default ProcurementTaskList;

const Task = ({ task, workspaceId }) => {

  const startDate = task.scheduled_start
    ? format(parseDate(task.scheduled_start), 'MMM dd, yyyy')
    : '';
  const endDate = task.scheduled_end ? format(parseDate(task.scheduled_end), 'MMM dd, yyyy') : '';
  const forecastedStart = task?.forecasted_start
    ? format(parseDate(task?.forecasted_start), 'MMM dd, yyyy')
    : startDate;
  const forecastedEnd = task?.forecasted_end
    ? format(parseDate(task?.forecasted_end), 'MMM dd, yyyy')
    : endDate;
  const percentComplete = task?.percent_complete ? task?.percent_complete * 100 : 0;

  return <>
    <Grid container item alignItems={'center'} component={ListItem} sx={{ px: 0.5, py: 0 }} xs={12}>
      <Grid container item alignItems={'center'} gap={1}>
        <Typography
          color={'primary'}
          component={RouterLink}
          sx={{ textDecoration: 'none', fontWeight: 'bold' }}
          to={`/workspace/${workspaceId}/projects/${task.project_id}/planning-board/gantt?task=${task.id}`}
        >
          {task?.name}
        </Typography>
        <TagList size="small" tags={task?.tags} />
        <Box sx={{ ml: 'auto' }}>
          <MenuOptions task={task} workspaceId={workspaceId} />
        </Box>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <FormLabel sx={{ fontSize: 15 }}> Planned Dates</FormLabel>
        <Stack alignItems="center" direction="row">
          <Typography variant="subtitle2">
            {startDate} - {endDate}
          </Typography>
        </Stack>
      </Grid>
      <Grid item md={3} sm={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <FormLabel sx={{ fontSize: 15 }}> Forecasted Dates</FormLabel>
        <Stack alignItems="center" direction="row">
          <Typography variant="subtitle2">
            {forecastedStart} - {forecastedEnd}
          </Typography>
        </Stack>
      </Grid>

      <Grid item md={3} sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <FormLabel sx={{ fontSize: 15 }}>Percent Complete</FormLabel>
        <Stack alignItems="center" direction="row" gap={0.5}>
          <LinearProgress
            sx={{ width: '50%', height: '10px' }}
            value={percentComplete}
            variant="determinate"
          />
          <Typography color="text.secondary" variant="body2">
            {`${Math.round(percentComplete)}%`}
          </Typography>
        </Stack>
      </Grid>

      <Grid item md={3} sm={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <FormLabel sx={{ fontSize: 15 }}>Leaders</FormLabel>
        {task?.responsible_users?.length ? (
          <Stack alignItems="center" direction="row" gap={0.5}>
            {task?.responsible_users?.map((user) => (
              <AvatarComponent
                name={user.name}
                src={user?.profile_image?.original_url}
                sx={{ height: 30, width: 30, fontSize: 16 }}
              />
            ))}
          </Stack>
        ) : (
          <Typography variant="subtitle2">NA</Typography>
        )}
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
  </>
}


export const MenuOptions = ({ task, workspaceId }) => {
  return (
    <>
      <OptionsMenu
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        triggerButton={
          <IconButton size="small">
            <MoreHoriz />
          </IconButton>
        }
      >
        {(handleClose) => {
          return (
            <MenuList>
              <MenuItem
                component={RouterLink}
                to={`/workspace/${workspaceId}/projects/${task?.project_id}/production-board/tasks/${task?.id}/details`}
              >
                <ListItemIcon>
                  <NavigateNext />
                </ListItemIcon>
                <ListItemText primary={'Task Details'} />
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to={`/workspace/${workspaceId}/projects/${task?.project_id}/planning-board/gantt?task=${task?.id}`}
              >
                <ListItemIcon>
                  <GanttIcon />
                </ListItemIcon>
                <ListItemText primary={'Show On Gantt'} />
              </MenuItem>

            </MenuList>
          );
        }}
      </OptionsMenu>
    </>
  );
};
