import Chip from '@mui/material/Chip';
import isEmpty from 'lodash/isEmpty';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { StyledDialog } from "@blackhyve/common";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { selectCurrentUser } from 'features/auth';
import FormControl from '@mui/material/FormControl';
import { parseDate } from '@blackhyve/utilities/dates';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ListItemButton from '@mui/material/ListItemButton';
import { GroupByCompanyListBoxComponent } from 'features/contacts/components/SelectContactList';
import { addDays, addWeeks, differenceInDays, differenceInWeeks, format, isValid } from 'date-fns';
import { useCreateProcurementMutation, useUpdateProcurementMutation } from '../store/procurement.api';
import { useGetProjectCompaniesQuery, useGetWorkspaceCompanyQuery } from 'features/projectCompanies/api/projectCompanies.api';

const initialState = {
  order_number: '',
  name: '',
  description: '',
  category: '',
  status: null,
  lead_time_weeks: 0,
  order_date: null,
  ship_date: null,
  transit_time_days: 0,
  arrival_date: null,
  cost: '',
  vendor: '',
  vendor_contact: '',
  responsible_users: [],
  leader_users: []
}

export const PROCUREMENT_STATUS_OPTIONS = [
  { id: 0, name: "None", value: null },
  { id: 1, name: "Submittals", value: "Submittals" },
  { id: 2, name: "Approved", value: "Approved" },
  { id: 3, name: "Ordered", value: "Ordered" },
  { id: 4, name: "Shipped", value: "Shipped" },
  { id: 5, name: "Delivered", value: "Delivered" },
]



/**
 * 
 * @param {boolean} open 
 * @param {object} handleClose 
 * @param {object} procurement 
 * @returns 
 */
const ProcurementFormDialog = ({ open, handleClose, procurement, projectId }) => {

  const { control, handleSubmit, reset, watch, setValue, setError } = useForm({ values: initialState });
  const [createProcurement, { isLoading: isCreatingProcurement }] = useCreateProcurementMutation();
  const [updateProcurement, { isLoading: isUpdatingProcurement }] = useUpdateProcurementMutation();

  const currentUser = useSelector((state) => selectCurrentUser(state));
  const { data: companies = [], isLoading: isLoadingCompanies } = useGetProjectCompaniesQuery({ projectId });
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({ projectId });
  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const companyEntities = Object.fromEntries(
    companies?.map(company => [company.id, company]) || []
  );

  const contacts = companies
    ?.filter(company => company.id !== userCompany.id)
    .flatMap(company => company.users);


  const handleSaveAndAddAnother = (data, e) => {
    handleSave(data, e, true);
  }

  const handleSave = (data, e, preventClose = false) => {
    try {
      const orderDate = data.order_date ? format(data.order_date, "yyyy-MM-dd") : null;
      const shipDate = data.ship_date ? format(data.ship_date, "yyyy-MM-dd") : null;
      const arrivalDate = data.arrival_date ? format(data.arrival_date, "yyyy-MM-dd") : null;

      const apiToCall = procurement?.id ? updateProcurement : createProcurement;

      apiToCall({
        ...data,
        projectId,
        order_date: orderDate,
        ship_date: shipDate,
        arrival_date: arrivalDate
      })
        .then((response) => {
          if (response?.error?.data?.errors) {
            handleError(response?.error?.data?.errors)
            return
          }
          reset({ ...initialState });
          !preventClose && handleClose()
        })
    } catch (response) {
      if (response?.data?.errors) {
        handleError(response.data.errors)
        return
      }
    }
  };

  const handleError = (errors) => {
    if (!isEmpty(errors)) {
      Object.entries(errors)?.forEach(([field, error]) => {
        setError(field, {
          type: 'manual',
          message: error?.at(0),
        });
      });
    }
  }

  const watchLeadTimeWeeks = watch('lead_time_weeks');
  const watchOrderDate = watch('order_date');
  const watchShipDate = watch('ship_date');
  const watchTransitTimeDays = watch('transit_time_days')

  useEffect(() => {
    if (!isEmpty(procurement)) {
      reset({
        ...procurement,
        order_date: procurement.order_date ? parseDate(procurement.order_date) : null,
        ship_date: procurement.ship_date ? parseDate(procurement.ship_date) : null,
        arrival_date: procurement.arrival_date ? parseDate(procurement.arrival_date) : null
      })
    }
  }, [procurement, reset])


  return <StyledDialog
    DialogContentProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
    handleClose={handleClose}
    maxWidth={'sm'}
    open={open}
    title={`${procurement?.id ? 'Update' : 'Create'} Procurement`}
    actions={
      <>
        <Button size="small" onClick={handleClose}>
          Close
        </Button>
        {isEmpty(procurement) &&
          <LoadingButton
            loading={isCreatingProcurement || isUpdatingProcurement}
            size="small"
            variant="contained"
            onClick={handleSubmit(handleSaveAndAddAnother)}
          >
            Save And Add Another
          </LoadingButton>
        }
        <LoadingButton
          loading={isCreatingProcurement || isUpdatingProcurement}
          size="small"
          variant="contained"
          onClick={handleSubmit(handleSave)}
        >
          {procurement?.id ? 'Update' : 'Create'}
        </LoadingButton>
      </>
    }
  >
    <Grid sx={{ width: '100%', margin: 'auto' }}>

      {/* Subsection 1: Order Information */}
      <Box sx={{ mb: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">Order Information</Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={1.5}>
          <Grid item xs={3}>
            <Controller
              control={control}
              name="order_number"
              rules={{ required: 'Order number is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  label="Order Number*"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={9}>
            <Controller
              control={control}
              name="name"
              rules={{ required: 'Name required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  label="Name*"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  multiline
                  error={error}
                  helperText={error?.message}
                  label="Description"
                  rows={2}
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <Controller
              control={control}
              name="category"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  label="Category"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <FormControl fullWidth size='small'>
              <InputLabel id="status-label">Status</InputLabel>
              <Controller
                control={control}
                name="status"
                rules={{ required: 'Status is required' }}
                render={({ field, fieldState: { error } }) => (
                  <Select {...field} error={error} helperText={error?.message} id="status-select" label='Status' labelId='status-label'>
                    {PROCUREMENT_STATUS_OPTIONS.map((status) => {
                      return <MenuItem key={status.id} value={status.value}>{status.name}</MenuItem>
                    })}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-amount">Cost</InputLabel>
              <Controller
                control={control}
                name="cost"
                render={({ field, fieldState: { error } }) => (
                  <OutlinedInput
                    {...field}
                    error={error}
                    helperText={error?.message}
                    id="outlined-adornment-amount"
                    label="Cost"
                    size='small'
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      {/* Subsection 2: Delivery Details */}
      <Box sx={{ mb: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">Delivery Details</Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={1.5}>
          <Grid item sm={4} xs={12}>
            <Controller
              control={control}
              name="lead_time_weeks"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  label="Lead time weeks"
                  size="small"
                  type='number'
                  variant="outlined"
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    setValue('lead_time_weeks', newValue);

                    if (watchOrderDate) {
                      const newShipDate = addWeeks(watchOrderDate, newValue)
                      setValue('ship_date', newShipDate);

                      if (watchTransitTimeDays) {
                        setValue('arrival_date', addDays(newShipDate, watchTransitTimeDays))
                      }

                    }

                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <Controller
              control={control}
              name="order_date"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  label="Order on"
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      error: error ? true : false,
                      helperText: error?.message
                    }
                  }}
                  onChange={(newValue) => {
                    setValue('order_date', newValue);
                    if (watchLeadTimeWeeks) {
                      const newShipDate = addWeeks(newValue, watchLeadTimeWeeks)
                      setValue('ship_date', newShipDate);

                      if (watchTransitTimeDays) {
                        setValue('arrival_date', addDays(newShipDate, watchTransitTimeDays));
                      }
                    }
                  }}
                />
              )}
              rules={{
                required: 'Order on date is required',
                validate: (value) => {
                  return isValid(value) || 'Invalid Date';
                },
              }}
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <Controller
              control={control}
              name="transit_time_days"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ min: 0 }}
                  label="Transit Days"
                  size="small"
                  type='number'
                  variant="outlined"
                  onChange={(event) => {
                    const newValue = parseInt(event.target.value);
                    setValue('transit_time_days', newValue);

                    if (watchShipDate) {
                      const newArrivalDate = addDays(watchShipDate, newValue)
                      setValue('arrival_date', newArrivalDate)
                    }
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <Controller
              control={control}
              name="ship_date"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  error={error ? true : false}
                  helperText={error?.message}
                  InputLabelProps={{ shrink: true }}
                  label="Ship Date"
                  minDate={watchOrderDate}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      error: error ? true : false,
                      helperText: error?.message
                    }
                  }}
                  onChange={(newValue) => {
                    setValue('ship_date', newValue);

                    if (watchOrderDate) {
                      const calculateLeadTimeWeeks = differenceInWeeks(newValue, watchOrderDate)
                      setValue('lead_time_weeks', calculateLeadTimeWeeks);
                    }

                    if (watchTransitTimeDays) {
                      const calculatedArrivalDate = addDays(newValue, watchTransitTimeDays);
                      setValue('arrival_date', calculatedArrivalDate)
                    }
                  }}
                />
              )}
              rules={{
                required: 'Ship date is required',
                validate: (value) => {
                  return isValid(value) || 'Invalid Date';
                },
              }}
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <Controller
              control={control}
              name="arrival_date"
              render={({ field: { ref, ...field }, fieldState: { error } }) => (
                <DatePicker
                  {...field}
                  error={error ? true : false}
                  helperText={error?.message}
                  label="Arrival Date"
                  minDate={watchShipDate}
                  slotProps={{
                    textField: {
                      size: 'small',
                      fullWidth: true,
                      error: error ? true : false,
                      helperText: error?.message
                    }
                  }}
                  onChange={(newValue) => {
                    setValue('arrival_date', newValue);

                    if (watchShipDate) {
                      setValue('transit_time_days', differenceInDays(newValue, watchShipDate))
                    }

                  }}
                />
              )}
              rules={{
                required: 'Arrival date is required',
                validate: (value) => {
                  return isValid(value) || 'Invalid Date';
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Subsection 3: Vendor Information */}
      <Box sx={{ mb: 1 }}>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">Stakeholders</Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={1.5}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="vendor"
              rules={{ required: 'Vendor name is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  label="Vendor Name*"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="vendor_contact"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error?.message}
                  label="Vendor Contact"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="leader_users"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disableCloseOnSelect
                  fullWidth
                  multiple
                  isOptionEqualToValue={(option, value) => option.id === value}
                  options={users}
                  getOptionLabel={(option) =>
                    option?.first_name ??
                    (users?.length && users.find((user) => user.id === option)?.first_name)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={error}
                      helperText={error?.message}
                      placeholder="Select Leaders"
                      size="small"
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <ListItem
                      {...props}
                      disablePadding
                      key={option.id}
                      secondaryAction={
                        option.isCurrentUser ? (
                          <Chip color="secondary" label="Logged in" size="small" />
                        ) : null
                      }
                    >
                      <ListItemIcon>
                        <Checkbox checked={selected} />
                      </ListItemIcon>
                      <ListItemText primary={`${option.first_name} ${option.last_name}`} />
                    </ListItem>
                  )}
                  onChange={(event, newValue) => {
                    field.onChange(newValue.map((user) => user?.id ?? user));
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="responsible_users"
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  disableCloseOnSelect
                  fullWidth
                  multiple
                  getOptionDisabled={(option) => (option.deleted_at ? true : false)}
                  groupBy={(option) => companyEntities[option.company_id].name}
                  isOptionEqualToValue={(option, value) => option.id === value}
                  ListboxComponent={GroupByCompanyListBoxComponent}
                  loading={isLoadingCompanies}
                  options={contacts}
                  size="small"
                  getOptionLabel={(option) =>
                    option?.first_name ??
                    (contacts?.length && contacts.find((contact) => contact.id === option)?.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={error}
                      helperText={error?.message}
                      placeholder="Select Responsible Users"
                      size="small"
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <ListItemButton {...props}>
                        <ListItemIcon>
                          <Checkbox checked={selected} />
                        </ListItemIcon>
                        <ListItemText style={option.deleted_at ? { textDecoration: 'line-through' } : {}}>
                          {option?.first_name + ' ' + option?.last_name}
                        </ListItemText>
                      </ListItemButton>
                    );
                  }}
                  onChange={(event, newValue) => {
                    if (event.key !== 'Backspace') {
                      field.onChange(newValue.map((contact) => contact?.id ?? contact));
                    }
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  </StyledDialog>

}

export default ProcurementFormDialog;