import ganttStore from '../../ganttStore';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from '@mui/material/DialogActions';
import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import { useGetProcurementsQuery } from 'features/procurement/store/procurement.api';


import MaterialList from '@mui/material/List';
import Box from '@mui/system/Box';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';


import { Clear } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';



import { List as VirtualizedList, AutoSizer, } from 'react-virtualized';





export const loadProcurementMenu = (ganttId) => {
  const gantt = ganttStore.getGantt(ganttId);
  let procurementEditorRef;
  gantt.config.editor_types.procurements = {
    show: function (id, column, config, placeholder) {
      const task = gantt.getTask(id);
      procurementEditorRef = createRef();
      gantt.config.external_render.renderElement(
        <ProcurementMenuPopover
          anchorEl={placeholder}
          initialSelected={task.procurements}
          key={`${task.id}-procurement-menu`}
          projectId={task.project_id}
          ref={procurementEditorRef}
          onClose={() => gantt.ext.inlineEditors.save()}
        />,
        placeholder
      );
    },
    hide: function () {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },

    set_value: function (value, id, column, node) {
      // set input value
      if (procurementEditorRef.current) {
        procurementEditorRef?.current?.setValue(new Set(value || []));
      }
    },

    get_value: function (id, column, node) {
      return procurementEditorRef?.current?.getValue();
      // return input value
    },

    is_changed: function (value, id, column, node) {
      //called before save/close. Return true if new value differs from the original one
      //returning true will trigger saving changes, returning false will skip saving
      const currentValue = this.get_value() || [];
      const oldValue = value || [];
      if (currentValue.length !== oldValue.length) {
        return true;
      }
      currentValue.sort();
      oldValue.sort();
      return !currentValue.every((element, index) => element === oldValue[index]);
    },

    is_valid: function (value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return Array.isArray(this.get_value());
    },
    focus: function (node) { },
  };
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 400,
    overflowY: 'auto',
    borderRadius: '8px',
    padding: '10px 10px',
  },
}));

const ProcurementMenuPopover = forwardRef(({ initialSelected, anchorEl, onClose, projectId }, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(initialSelected || []);


  // const [debouncedSearchQuery, setDebouncedSearchQuery] = useDebounceValue('');

  const { data: { data: procurementList = [], meta: { total = 0 } = {} } = {}, isLoading, isFetching } = useGetProcurementsQuery({
    projectId,
    // q: debouncedSearchQuery
  })

  const handleClose = () => {
    setOpen(false);
    onClose(selected);
  };

  const handleSubmit = () => {
    handleClose();
  };

  const handleCancel = () => {
    setOpen(false);
    setSelected(initialSelected);
    onClose(initialSelected);
  };

  useImperativeHandle(ref, () => ({
    getValue: () => [...selected],
    setValue: setSelected,
  }));

  console.log('selected =>', selected)

  return (
    <>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          classes={{ paper: classes.paper }}
          open={open}
          onClose={handleSubmit}
        >

          <Autocomplete
            disableClearable
            disableCloseOnSelect
            disableListWrap
            fullWidth
            multiple
            // filterOptions={(x) => x}
            autoFocus={false}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            limitTags={3}
            ListboxComponent={ListboxComponent}
            loading={isLoading}
            options={procurementList}
            renderTags={() => null}
            value={selected}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Select Procurement"
                size="small"
                variant="outlined"
              // slotProps={{
              //   input: {
              //     ...params.InputProps,
              //     endAdornment: (
              //       <>
              //         {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
              //         {params.InputProps.endAdornment}
              //       </>
              //     ),
              //   },
              // }}
              />
            )}
            renderOption={(props, option, { selected }) => {
              return (
                <ListItemButton {...props}>
                  <ListItemIcon>
                    <Checkbox checked={selected} />
                  </ListItemIcon>
                  <ListItemText>{option.name}</ListItemText>
                </ListItemButton>
              );
            }}
            onChange={(event, newValue) => {
              if (event.key !== 'Backspace') {
                setSelected(newValue);
              }
            }}
          // onInputChange={(event, newInputValue) => {
          //   setDebouncedSearchQuery(newInputValue);
          // }}
          />

          <Box dense component={MaterialList} maxHeight={'25vh'} sx={{ overflowY: 'auto', padding: '5px' }}>
            {selected?.map((procurement) => {
              return (
                <ListItemButton disableGutters key={procurement.id}>
                  <ListItemText primary={procurement?.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      size="large"
                      onClick={() => {
                        setSelected(selected.filter((selectedUser) => selectedUser.id !== procurement.id));
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </ListItemSecondaryAction>

                </ListItemButton>
              );
            })}
          </Box>
          <DialogActions>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
            <Button size="small" variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Popover>
      )}
    </>
  );
});


const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 48; // height of each list item
  const listHeight = itemSize * itemCount

  return (
    <div ref={ref} {...other}>
      <div>
        <AutoSizer disableHeight>
          {({ width }) => (
            <VirtualizedList
              height={Math.min(listHeight, 250)}
              overscanRowCount={5}
              rowCount={itemCount}
              rowHeight={itemSize}
              width={width}
              rowRenderer={({ index, key, style }) => {
                return (
                  <div
                    key={key}
                    style={{
                      ...style,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {(children)[index]}
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
});