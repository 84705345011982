const { api, providesList } = require('api');

export const TAGS_CACHE_KEY = 'Tag';

const tagAPI = api.enhanceEndpoints({ addTagTypes: ['Tag'] }).injectEndpoints({
  endpoints: (build) => ({
    createTag: build.mutation({
      query: ({ taskId, ...body }) => ({
        url: '/tags',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, args) =>
        result ? [{ id: 'LIST', type: 'Tag' }] : [],
    }),
    updateTag: build.mutation({
      query: ({ id, ...tag }) => ({
        url: `/tags/${id}`,
        method: 'PATCH',
        body: tag,
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Tag', id: params.id }] : [],
    }),
    getTags: build.query({
      query: () => ({
        url: '/tags'
      }),
      providesTags: (result, error, args) => result?.length ? providesList(result, 'Tag') : [],
      transformResponse: (response) => response.data
    }),
    deleteTag: build.mutation({
      query: (id) => ({
        url: `/tags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Tag', id: params.id }] : [],
    }),
    getProjectGeneratedTags: build.query({
      query: (projectId) => ({
        url: `/projects/${projectId}/generated_tags`,
        method: 'GET'
      }),
      providesTags: (result, error, args) => result?.length ? providesList(result, 'Tag') : [],
      transformResponse: (response) => response.data
    })
  }),
});

export const {
  useCreateTagMutation,
  useGetTagsQuery,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useGetProjectGeneratedTagsQuery
} = tagAPI;
