import { parseDate } from '@blackhyve/utilities/dates';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, addWeeks, differenceInDays, differenceInWeeks, format } from 'date-fns';
import { selectCurrentUser } from 'features/auth';
import { GroupByCompanyListBoxComponent } from 'features/contacts/components/SelectContactList';
import {
  useGetProjectCompaniesQuery,
  useGetWorkspaceCompanyQuery,
} from 'features/projectCompanies/api/projectCompanies.api';
import { ResponsibleUser } from 'features/stuckPoints/components/StuckPointTable';
import { useGetProjectGeneratedTagsQuery } from 'features/tags/store/tag.api';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import ProcurementMoreOptions from '../components/ProcurementMoreOptions';
import { CompanyContacts, PROCUREMENT_STATUS_COLORS } from '../components/ProcurementTable';

const RenderEditLeadTimeCell = (props) => {
  const ref = useRef();
  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <TextField
      fullWidth
      inputRef={ref}
      size="small"
      type="number"
      value={props.value}
      variant="outlined"
      sx={{
        '& .MuiInputBase-root': {
          height: '100%',
        },
      }}
      onChange={(e) => {
        const updatedValue = parseInt(e.target.value) || 0;
        const { order_date, transit_time_days } = props.row;

        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: updatedValue,
        });
        if (order_date) {
          const orderDate = order_date instanceof Date ? order_date : parseDate(order_date);
          const newShipDate = addWeeks(orderDate, updatedValue);
          props.api.setEditCellValue({
            id: props.id,
            field: 'ship_date',
            value: format(newShipDate, 'yyyy-MM-dd'),
          });
          if (transit_time_days) {
            props.api.setEditCellValue({
              id: props.id,
              field: 'arrival_date',
              value: format(addDays(newShipDate, transit_time_days), 'yyyy-MM-dd'),
            });
          }
        }
      }}
    />
  );
};

const RenderEditTransitTimeCell = (props) => {
  const ref = useRef();

  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <TextField
      fullWidth
      inputRef={ref}
      size="small"
      type="number"
      value={props.value}
      variant="outlined"
      sx={{
        '& .MuiInputBase-root': {
          height: '100%',
        },
      }}
      onChange={(e) => {
        const updatedValue = parseInt(e.target.value) || 0;
        const { ship_date } = props.row;

        if (ship_date) {
          const newArrivalDate = addDays(parseDate(ship_date), updatedValue);
          props.api.setEditCellValue({
            id: props.id,
            field: 'arrival_date',
            value: format(newArrivalDate, 'yyyy-MM-dd'),
          });
          props.api.setEditCellValue({
            id: props.id,
            field: props.field,
            value: updatedValue,
          });
        }
      }}
    />
  );
};

const RenderOrderOnCell = (props) => {
  const ref = useRef();
  const value = props.value
    ? props?.value instanceof Date
      ? props.value
      : parseDate(props.value)
    : undefined;

  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <DatePicker
      {...props}
      inputRef={ref}
      value={value}
      slotProps={{
        textField: {
          size: 'small',
          fullWidth: true,
          error: props.error,
          sx: {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
        },
      }}
      onChange={(newValue) => {
        const { lead_time_weeks, transit_time_days } = props.row;
        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: newValue,
        });

        if (lead_time_weeks) {
          const newShipDate = addWeeks(newValue, lead_time_weeks);
          props.api.setEditCellValue({
            id: props.id,
            field: 'ship_date',
            value: format(newShipDate, 'yyyy-MM-dd'),
          });

          if (transit_time_days) {
            const newArrivalDate = addDays(newShipDate, transit_time_days);
            props.api.setEditCellValue({
              id: props.id,
              field: 'arrival_date',
              value: format(newArrivalDate, 'yyyy-MM-dd'),
            });
          }
        }
      }}
    />
  );
};

const RenderShipDateCell = (props) => {
  const ref = useRef();
  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);
  const value = props.value
    ? props?.value instanceof Date
      ? props.value
      : parseDate(props.value)
    : undefined;

  return (
    <DatePicker
      {...props}
      inputRef={ref}
      minDate={props?.row?.order_date}
      value={value}
      slotProps={{
        textField: {
          size: 'small',
          error: props.error,
          fullWidth: true,
          sx: {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
        },
      }}
      onChange={(newValue) => {
        const { order_date, transit_time_days } = props.row;
        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: newValue,
        });

        if (order_date) {
          const orderDate = order_date instanceof Date ? order_date : parseDate(order_date);
          const calculateLeadTimeWeeks = differenceInWeeks(newValue, orderDate);
          props.api.setEditCellValue({
            id: props.id,
            field: 'lead_time_weeks',
            value: calculateLeadTimeWeeks,
          });
        }

        if (transit_time_days) {
          const calculatedArrivalDate = addDays(newValue, transit_time_days);
          props.api.setEditCellValue({
            id: props.id,
            field: 'arrival_date',
            value: calculatedArrivalDate,
          });
        }
      }}
    />
  );
};

const RenderArrivalDateCell = (props) => {
  const ref = useRef();
  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);
  const value = props.value
    ? props?.value instanceof Date
      ? props.value
      : parseDate(props.value)
    : undefined;

  return (
    <DatePicker
      {...props}
      inputRef={ref}
      minDate={props?.row?.ship_date}
      value={value}
      slotProps={{
        textField: {
          size: 'small',
          fullWidth: true,
          sx: {
            '& .MuiInputBase-root': {
              height: '100%',
            },
          },
        },
      }}
      onChange={(newValue) => {
        const { ship_date } = props.row;
        props.api.setEditCellValue({
          id: props.id,
          field: props.field,
          value: newValue,
        });

        if (ship_date) {
          props.api.setEditCellValue({
            id: props.id,
            field: 'transit_time_days',
            value: differenceInDays(newValue, ship_date),
          });
        }
      }}
    />
  );
};

const RenderLeaderDataCell = (props) => {
  const currentUser = useSelector((state) => selectCurrentUser(state));
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({
    projectId: props.row.project_id,
  });

  const ref = useRef();
  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  const users = useMemo(() => {
    const users =
      currentUser.role === 'super_admin'
        ? [{ ...currentUser, isCurrentUser: true }, ...userCompany.users]
        : [...userCompany.users];
    return users;
  }, [userCompany, currentUser]);

  const handleValueChange = (event, newValue) => {
    props.api.setEditCellValue({
      id: props.id,
      field: props.field,
      value: newValue.map((user) => user?.id ?? user),
    });
  };

  return (
    <Autocomplete
      disableCloseOnSelect
      fullWidth
      multiple
      isOptionEqualToValue={(option, value) => option.id === value}
      name="leader_users"
      options={users}
      getOptionLabel={(option) =>
        option?.first_name ??
        (users?.length && users.find((user) => user.id === option)?.first_name)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          helperText={props.error?.message}
          inputRef={ref}
          size="small"
          sx={{
            '&.MuiFormControl-root': {
              height: '100%',
            },
            '& .MuiInputBase-root': {
              height: '100%',
            },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <ListItem {...props} disablePadding key={option.id}>
          <ListItemIcon>
            <Checkbox checked={selected} />
          </ListItemIcon>
          <ListItemText primary={`${option.first_name} ${option.last_name}`} />
        </ListItem>
      )}
      onChange={handleValueChange}
      limitTags={1}
      // renderTags={() => null}
      value={props.value}
    />
  );
};

const RenderResponsibleDataCell = (props) => {
  const { data: companies = [], isLoading: isLoadingCompanies } = useGetProjectCompaniesQuery({
    projectId: props.row.project_id,
  });
  const { data: userCompany = { users: [] } } = useGetWorkspaceCompanyQuery({
    projectId: props.row.project_id,
  });

  const companyEntities = Object.fromEntries(
    companies?.map((company) => [company.id, company]) || []
  );

  const contacts = companies
    ?.filter((company) => company.id !== userCompany.id)
    .flatMap((company) => company.users);

  const handleValueChange = (event, newValue) => {
    if (event.key !== 'Backspace') {
      props.api.setEditCellValue({
        id: props.id,
        field: props.field,
        value: newValue.map((user) => user?.id ?? user),
      });
    }
  };

  return (
    <Autocomplete
      disableCloseOnSelect
      fullWidth
      multiple
      getOptionDisabled={(option) => (option.deleted_at ? true : false)}
      groupBy={(option) => companyEntities[option.company_id].name}
      isOptionEqualToValue={(option, value) => option.id === value}
      ListboxComponent={GroupByCompanyListBoxComponent}
      loading={isLoadingCompanies}
      options={contacts}
      value={props.value}
      getOptionLabel={(option) =>
        option?.first_name ??
        (contacts?.length && contacts.find((contact) => contact.id === option)?.name)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={props.error}
          helperText={props.error?.message}
          // placeholder="Select Responsible Users"
          size="small"
          variant="outlined"
          sx={{
            '&.MuiFormControl-root': {
              height: '100%',
            },
            '& .MuiInputBase-root': {
              height: '100%',
            },
          }}
        />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItemButton {...props}>
            <ListItemIcon>
              <Checkbox checked={selected} />
            </ListItemIcon>
            <ListItemText style={option.deleted_at ? { textDecoration: 'line-through' } : {}}>
              {option?.first_name + ' ' + option?.last_name}
            </ListItemText>
          </ListItemButton>
        );
      }}
      onChange={handleValueChange}
      limitTags={1}
      // renderTags={() => null}
      size="small"
    />
  );
};

const RenderTagDropdown = (props) => {
  const ref = useRef();
  const { data: generatedTags = [], isLoading } = useGetProjectGeneratedTagsQuery(
    props.row.project_id
  );

  const handleChange = (event) => {
    console.log('event =>', event.target.value);
    props.api.setEditCellValue({
      id: props.id,
      field: props.field,
      value: [event.target.value],
    });
  };

  useLayoutEffect(() => {
    if (props.hasFocus) {
      ref.current.focus();
    }
  }, [props.hasFocus]);

  return (
    <TextField
      fullWidth
      select
      inputRef={ref}
      value={props?.value?.length ? props?.value?.map((tag) => tag?.id || tag) : []}
      onChange={handleChange}
    >
      {generatedTags?.map((tag) => {
        return (
          <MenuItem key={tag.id} value={tag.id}>
            {tag.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export const ProcurementDataGridColumns = {
  order_number: {
    field: 'order_number',
    headerName: 'No',
    editable: true,
    width: 80,
  },
  name: {
    headerName: 'Name',
    field: 'name',
    width: 250,
    editable: true,
  },
  description: {
    headerName: 'Description',
    field: 'description',
    width: 200,
    editable: true,
  },
  category: {
    headerName: 'Category',
    field: 'category',
    width: 120,
    editable: true,
  },
  lbs: {
    headerName: 'LBS',
    field: 'tags',
    width: 200,
    editable: true,
    renderCell: (props) => {
      return (
        <Box display="inline">
          {props.value?.map((tag, index) => {
            return (
              <Typography component="span" key={tag.id} variant="subtitle2">
                {tag.name}
              </Typography>
            );
          })}
        </Box>
      );
    },
    renderEditCell: (params) => {
      return <RenderTagDropdown {...params} />;
    },
  },
  status: {
    headerName: 'Status',
    field: 'status',
    width: 100,
    editable: true,
    type: 'singleSelect',
    valueOptions: Object.keys(PROCUREMENT_STATUS_COLORS),
    renderCell: (params) => {
      return (
        <Chip
          color={PROCUREMENT_STATUS_COLORS[params?.value]}
          label={params?.value}
          size="small"
          sx={{ '& .MuiChip-label': { mt: '1px' } }}
          variant="contained"
        />
      );
    },
    preProcessEditCellProps: (params) => {
      const hasError = !params.props.value;
      return { ...params.props, error: hasError };
    },
  },
  order_date: {
    headerName: 'Ordered On',
    field: 'order_date',
    width: 150,
    type: 'date',
    editable: true,
    valueFormatter: (value) => {
      return value && parseDate(value).toLocaleDateString();
    },
    renderEditCell: (params) => {
      return <RenderOrderOnCell {...params} />;
    },
    preProcessEditCellProps: (params) => {
      const hasError = !params.props.value;
      return { ...params.props, error: hasError };
    },
  },
  lead_time_weeks: {
    headerName: 'Lead Time',
    field: 'lead_time_weeks',
    width: 90,
    editable: true,
    valueFormatter: (value, row) => {
      return `${value} Week`;
    },
    renderEditCell: (params) => {
      return <RenderEditLeadTimeCell {...params} />;
    },
  },
  ship_date: {
    headerName: 'Ship Date',
    field: 'ship_date',
    width: 150,
    type: 'date',
    editable: true,
    valueFormatter: (value) => {
      return value && parseDate(value).toLocaleDateString();
    },
    renderEditCell: (params) => {
      return <RenderShipDateCell {...params} />;
    },
  },
  transit_time_days: {
    headerName: 'Transit Time',
    label: 'Transit Days',
    field: 'transit_time_days',
    editable: true,
    width: 90,
    valueFormatter: (value, row) => {
      return `${value} Days`;
    },
    renderEditCell: (params) => {
      return <RenderEditTransitTimeCell {...params} />;
    },
  },
  arrival_date: {
    headerName: 'Arrival Date',
    field: 'arrival_date',
    width: 150,
    type: 'date',
    editable: true,
    valueFormatter: (value, row) => {
      return value && parseDate(value).toLocaleDateString();
    },
    renderEditCell: (params) => {
      return <RenderArrivalDateCell {...params} />;
    },
  },
  cost: {
    headerName: 'Cost',
    field: 'cost',
    width: 120,
    editable: true,
    valueFormatter: (value) => {
      return value ? `$${value}` : '';
    },
  },
  vendor: {
    headerName: 'Vendor',
    field: 'vendor',
    width: 180,
    editable: true,
  },
  vendor_contact: {
    headerName: 'Vendor Contact',
    field: 'vendor_contact',
    width: 180,
    editable: true,
  },
  leader: {
    headerName: 'Leaders',
    field: 'leader_users',
    width: 250,
    editable: true,
    renderCell: (params) => {
      return <ResponsibleUser projectId={params.row.project_id} userIds={params.value} />;
    },
    renderEditCell: (params) => {
      return <RenderLeaderDataCell {...params} />;
    },
  },
  responsible_users: {
    headerName: 'Responsible',
    field: 'responsible_users',
    width: 250,
    editable: true,
    renderCell: (params) => {
      return <CompanyContacts contactIds={params.value} projectId={params.row.project_id} />;
    },
    renderEditCell: (params) => {
      return <RenderResponsibleDataCell {...params} />;
    },
  },
  actions: {
    field: 'actions',
    type: 'actions',
    width: 50,
    renderCell: (props) => {
      return <ProcurementMoreOptions procurement={props.row} />;
    },
  },
};
