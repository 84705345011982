
import List from '@mui/material/List';
import { useContext, useState } from 'react';
import { Popover } from 'components/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ConfirmDeleteDialog } from '@blackhyve/common';
import { useNavigate, useParams } from 'react-router-dom';
import { Delete, MoreVert, OpenInNew } from '@mui/icons-material';
import { EditContext } from 'components/common/v3/DisplayWithEditAccess';
import { useDeleteProcurementMutation } from '../store/procurement.api';

/**
 * Procurement More Options
 * @param {object} procurement 
 * @returns 
 */
const ProcurementMoreOptions = ({ procurement }) => {

  const navigate = useNavigate();
  const canEdit = useContext(EditContext);
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const projectId = Number(useParams().projectId) || undefined;
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [deleteProcurement] = useDeleteProcurementMutation();

  const handleDetailPage = () => {
    navigate(`/workspace/${workspaceId}/projects/${projectId}/procurements/${procurement.id}`)
  }

  const handleDelete = () => {
    deleteProcurement(procurement.id)
  };

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        trigger={
          <IconButton edge="end" size={'small'}>
            <MoreVert />
          </IconButton>
        }
      >
        {({ handleClose }) => {
          return (
            <List>
              <MenuItem
                onClick={() => {
                  handleDetailPage();
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <OpenInNew />
                </ListItemIcon>
                <ListItemText primary={'Details'} />
              </MenuItem>
              <MenuItem
                disabled={!canEdit}
                onClick={() => {

                  setConfirmDeleteDialogOpen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <Delete color={'error'} />
                </ListItemIcon>
                <ListItemText primary={'Delete'} sx={{ color: 'error.main' }} />
              </MenuItem>
            </List>
          );
        }}
      </Popover>

      {confirmDeleteDialogOpen && (
        <ConfirmDeleteDialog
          handleClose={() => setConfirmDeleteDialogOpen(false)}
          handleDelete={handleDelete}
          item={'procurement'}
          open={confirmDeleteDialogOpen}
        />
      )}
    </>
  );
};

export default ProcurementMoreOptions;