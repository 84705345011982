import { useState } from 'react';
import keyBy from 'lodash/keyBy';
import Box from '@mui/material/Box';
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce';
import identity from 'lodash/identity';
import Grid from "@mui/material/Grid";
import Chip from '@mui/material/Chip';
import Paper from "@mui/material/Paper";
import Toolbar from '@mui/material/Toolbar';
import Skeleton from '@mui/material/Skeleton';
import { Table } from "components/table/Table";
import Typography from '@mui/material/Typography'
import SearchBox from 'components/search/SearchBox';
import { parseDate } from "@blackhyve/utilities/dates";
import TableContainer from "@mui/material/TableContainer";
import { useNavigate, useParams } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import ProcurementMoreOptions from "./ProcurementMoreOptions";
import SingleSelectMenu from 'components/menus/SingleSelectMenu';
import { useGetProcurementsQuery } from "../store/procurement.api";
import { PROCUREMENT_STATUS_OPTIONS } from './ProcurementFormDialog';
import { ResponsibleUser } from "features/stuckPoints/components/StuckPointTable";
import { useGetProjectCompaniesQuery } from 'features/projectCompanies/api/projectCompanies.api';

export const PROCUREMENT_STATUS_COLORS = {
  'Submittals': 'error',
  'Approved': 'primary',
  'Ordered': 'warning',
  'Shipped': 'info',
  'Delivered': 'secondary',
}

export const ProcurementTableColumns = {
  order_number: {
    label: 'No',
    field: 'order_number',
    sort: true,
    render: ({ order_number }) => {
      return order_number;
    },
  },
  name: {
    label: 'Name',
    field: 'name',
    sort: true,
    render: ({ name }) => {
      return name;
    },
  },
  description: {
    label: 'Description',
    field: 'description',
    render: ({ description }) => {
      return description ? description : '-';
    },
  },
  category: {
    label: 'Category',
    field: 'category',
    sort: true,
    render: ({ category }) => {
      return category;
    },
  },
  status: {
    label: 'Status',
    field: 'status',
    sort: true,
    render: ({ status }) => {

      return <Chip color={PROCUREMENT_STATUS_COLORS[status]} label={status} size="small" sx={{ '& .MuiChip-label': { mt: '1px' } }} variant="contained" />
    }
  },
  // lead_time_weeks: {
  //   label: 'Lead Time (weeks)',
  //   field: 'lead_time_weeks',
  //   render: ({ lead_time_weeks }) => {
  //     return lead_time_weeks
  //   },
  // },
  order_date: {
    label: 'Ordered On',
    field: 'order_date',
    sort: true,
    render: ({ order_date }) => {
      return order_date && parseDate(order_date).toLocaleDateString();
    },
  },
  ship_date: {
    label: 'Ship Date',
    field: 'ship_date',
    sort: true,
    render: ({ ship_date }) => {
      return ship_date && parseDate(ship_date).toLocaleDateString();
    },
  },
  // transit_time_days: {
  //   label: 'Transit Days',
  //   field: 'transit_time_days',
  //   render: ({ transit_time_days }) => {
  //     return transit_time_days
  //   },
  // },
  arrival_date: {
    label: 'Arrival Date',
    field: 'arrival_date',
    sort: true,
    render: ({ arrival_date }) => {
      return arrival_date && parseDate(arrival_date).toLocaleDateString();
    },
  },
  cost: {
    label: 'Cost',
    field: 'cost',
    sort: true,
    render: ({ cost }) => {
      return cost ? `$${cost}` : ''
    },
  },
  vendor: {
    label: 'Vendor',
    field: 'vendor_contact',
    render: ({ vendor_contact }) => {
      return vendor_contact
    },
  },
  leader: {
    label: 'Leaders',
    field: 'leaders',
    render: ({ leader_users, project_id }) => {
      return leader_users?.length ? <ResponsibleUser projectId={project_id} userIds={leader_users} /> : '-'
    },
  },
  responsible_users: {
    label: 'Responsible',
    field: 'responsible_users',
    render: ({ responsible_users, project_id }) => {
      return responsible_users?.length ? <CompanyContacts contactIds={responsible_users} projectId={project_id} /> : '-';
    },
  },
  // comments: {
  //   label: 'Comments',
  //   field: 'comments',
  //   render: ({ comments_count }) => {
  //     return (
  //       <Stack direction={'row'} spacing={1}>
  //         <ForumOutlinedIcon color={comments_count > 0 ? 'info' : ''} />
  //         <Typography>{comments_count}</Typography>
  //       </Stack>
  //     );
  //   },
  // },
  moreOptions: {
    field: 'moreOptions',
    cellProps: { align: 'right', onClick: (e) => e.stopPropagation() },
    render: (procurement, meta) => <ProcurementMoreOptions procurement={procurement} />
  }
}

const columns = Object.values(ProcurementTableColumns)



/**
 * Procurement Table
 * @returns 
 */
const ProcurementTable = () => {

  const navigate = useNavigate();
  const projectId = Number(useParams().projectId) || undefined;
  const workspaceId = Number(useParams().workspaceId) || undefined;
  const [filters, setFilters] = useState({ status: null, page: 1, per_page: 50, order_by: 'created_at,desc' });

  const { data: { data: procurementList = [], meta: { total = 0 } = {} } = {},
    isLoading, isFetching } = useGetProcurementsQuery({
      projectId,
      ...pickBy(filters, identity)
    })

  const handleSearch = debounce((event) => {
    const searchQuery = event.target.value.trim();
    setFilters((filters) => ({ ...filters, page: 1, q: searchQuery || undefined, }));
  }, 500);

  const handleSelectStatusFilter = (newStatusValue) => {
    setFilters({ ...filters, status: newStatusValue, page: 1 })
  }

  const handleRedirectToDetailsPage = (row) => {
    navigate(
      `/workspace/${workspaceId}/projects/${projectId}/procurements/${row?.id}`
    );
  }

  const handleSort = (property, newSortOrder) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      order_by: `${property},${newSortOrder}`, page: 1
    }))
  }

  return <Paper elevation={2}>
    <Toolbar style={{ position: 'sticky', left: '0px' }}>
      <Grid container item>
        <SearchBox
          bgcolor={'grey.200'}
          borderRadius={1}
          fullWidth={false}
          placeholder="Search By Name"
          onChange={handleSearch}
        />
        <Grid container item xs justifyContent={'flex-end'} spacing={1}>
          <Grid item>
            <SingleSelectMenu
              handleSelect={handleSelectStatusFilter}
              label="Filter By Status"
              options={PROCUREMENT_STATUS_OPTIONS}
              selected={filters?.status}
            />
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
    <TableContainer sx={{ maxHeight: 'calc(100vh - 260px)' }}>
      <Table
        columns={columns}
        defaultOrderBy={false}
        handleSort={handleSort}
        isFetching={isFetching}
        isLoading={isLoading}
        rows={procurementList}
        size="small"
        stickyHeader={true}
        rowProps={(row) => ({
          onClick: (e) => {
            handleRedirectToDetailsPage(row);
          },
          sx: { cursor: 'pointer' },
        })}
      />

    </TableContainer>
    {!isLoading && (
      <TablePagination
        component={'div'}
        count={total}
        page={filters?.page - 1}
        rowsPerPage={filters?.per_page}
        rowsPerPageOptions={[1, 25, 50, 100]}
        sx={{ ml: 'auto' }}
        onPageChange={(_, page) => setFilters((prevFilters) => ({
          ...prevFilters,
          page: page + 1
        }))}
        onRowsPerPageChange={(event) => {
          setFilters({ ...filters, per_page: event.target.value, page: 1 })
        }}
      />
    )}
  </Paper>
}


export default ProcurementTable;

/**
 * Company Contacts 
 * @param {[string]} contactIds
 * @returns
 */
export const CompanyContacts = ({ projectId, contactIds }) => {

  const { data: companies = [], isLoading } = useGetProjectCompaniesQuery({ projectId });
  const contacts = keyBy(companies?.flatMap(company => company.users), 'id');

  if (isLoading) {
    return <Skeleton height={50} width={100} />
  }

  return <Box display="inline">
    {contactIds?.map((contactId, index) => {
      const contact = contacts[contactId];
      return (
        <Typography component="span" key={contactId} variant="subtitle2">
          {contact?.name}
          {contactIds?.length > index + 1 ? ', ' : ''}
        </Typography>
      );
    })}
  </Box>
};


