import isEmpty from 'lodash/isEmpty';


export const MOCK_PROCUREMENT_TASK_LIST = [
  {
    "object_type": "task",
    "id": 18194,
    "uuid": "TTE2-0001",
    "name": "Ski Lodge",
    "description": null,
    "parent_id": 0,
    "is_parent": false,
    "display_order": 1,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-11-06",
    "timestamp": 1730884976,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 1,
        "type": "smart",
        "name": "location",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": null,
        "description": null,
        "created_at": "2024-10-08T04:01:20.000000Z",
        "updated_at": "2024-10-08T04:01:20.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18201,
    "uuid": "TTE2-0008",
    "name": "Floor 1",
    "description": null,
    "parent_id": 18195,
    "is_parent": true,
    "display_order": 2,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": "0.00",
    "earned_planned_days": "0.00",
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": "0.00",
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 180,
        "type": "generated",
        "name": "East Lodge",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "location",
        "smart_task_id": 18195,
        "smart_tag_id": 1,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:36:15.000000Z",
        "updated_at": "2024-10-23T07:36:15.000000Z"
      },
      {
        "object_type": "tag",
        "id": 3,
        "type": "smart",
        "name": "zone",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": null,
        "description": null,
        "created_at": "2024-10-08T04:01:20.000000Z",
        "updated_at": "2024-10-08T04:01:20.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18202,
    "uuid": "TTE2-0009",
    "name": "Floor 2",
    "description": null,
    "parent_id": 18195,
    "is_parent": false,
    "display_order": 3,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 180,
        "type": "generated",
        "name": "East Lodge",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "location",
        "smart_task_id": 18195,
        "smart_tag_id": 1,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:36:15.000000Z",
        "updated_at": "2024-10-23T07:36:15.000000Z"
      },
      {
        "object_type": "tag",
        "id": 3,
        "type": "smart",
        "name": "zone",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": null,
        "description": null,
        "created_at": "2024-10-08T04:01:20.000000Z",
        "updated_at": "2024-10-08T04:01:20.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18203,
    "uuid": "TTE2-0010",
    "name": "Floor 3",
    "description": null,
    "parent_id": 18195,
    "is_parent": false,
    "display_order": 4,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 180,
        "type": "generated",
        "name": "East Lodge",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "location",
        "smart_task_id": 18195,
        "smart_tag_id": 1,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:36:15.000000Z",
        "updated_at": "2024-10-23T07:36:15.000000Z"
      },
      {
        "object_type": "tag",
        "id": 3,
        "type": "smart",
        "name": "zone",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": null,
        "description": null,
        "created_at": "2024-10-08T04:01:20.000000Z",
        "updated_at": "2024-10-08T04:01:20.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18210,
    "uuid": "TTE2-0017",
    "name": "Foundation and excavation",
    "description": null,
    "parent_id": 18201,
    "is_parent": false,
    "display_order": 5,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 180,
        "type": "generated",
        "name": "East Lodge",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "location",
        "smart_task_id": 18195,
        "smart_tag_id": 1,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:36:15.000000Z",
        "updated_at": "2024-10-23T07:36:15.000000Z"
      },
      {
        "object_type": "tag",
        "id": 186,
        "type": "generated",
        "name": "Floor 1",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "zone",
        "smart_task_id": 18201,
        "smart_tag_id": 3,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:37:49.000000Z",
        "updated_at": "2024-10-23T07:37:49.000000Z"
      },
      {
        "object_type": "tag",
        "id": 157,
        "type": "standard",
        "name": "Foundation",
        "tagged_by": 1,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": "#5E3A31",
        "description": null,
        "created_at": "2024-10-17T10:18:37.000000Z",
        "updated_at": "2024-10-17T10:18:37.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18211,
    "uuid": "TTE2-0018",
    "name": "Electrical",
    "description": null,
    "parent_id": 18201,
    "is_parent": false,
    "display_order": 6,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 180,
        "type": "generated",
        "name": "East Lodge",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "location",
        "smart_task_id": 18195,
        "smart_tag_id": 1,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:36:15.000000Z",
        "updated_at": "2024-10-23T07:36:15.000000Z"
      },
      {
        "object_type": "tag",
        "id": 186,
        "type": "generated",
        "name": "Floor 1",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "zone",
        "smart_task_id": 18201,
        "smart_tag_id": 3,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:37:49.000000Z",
        "updated_at": "2024-10-23T07:37:49.000000Z"
      },
      {
        "object_type": "tag",
        "id": 157,
        "type": "standard",
        "name": "Foundation",
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": "#5E3A31",
        "description": null,
        "created_at": "2024-10-17T10:18:37.000000Z",
        "updated_at": "2024-10-17T10:18:37.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18212,
    "uuid": "TTE2-0019",
    "name": "Plumbing",
    "description": null,
    "parent_id": 18201,
    "is_parent": false,
    "display_order": 7,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844535,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 180,
        "type": "generated",
        "name": "East Lodge",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "location",
        "smart_task_id": 18195,
        "smart_tag_id": 1,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:36:15.000000Z",
        "updated_at": "2024-10-23T07:36:15.000000Z"
      },
      {
        "object_type": "tag",
        "id": 186,
        "type": "generated",
        "name": "Floor 1",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 0,
        "smart_key": "zone",
        "smart_task_id": 18201,
        "smart_tag_id": 3,
        "color": null,
        "description": null,
        "created_at": "2024-10-23T07:37:49.000000Z",
        "updated_at": "2024-10-23T07:37:49.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18195,
    "uuid": "TTE2-0002",
    "name": "East Lodge",
    "description": null,
    "parent_id": 0,
    "is_parent": true,
    "display_order": 8,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": "0.00",
    "earned_planned_days": "0.00",
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": "0.00",
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 1,
        "type": "smart",
        "name": "location",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": null,
        "description": null,
        "created_at": "2024-10-08T04:01:20.000000Z",
        "updated_at": "2024-10-08T04:01:20.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18196,
    "uuid": "TTE2-0003",
    "name": "West Lodge",
    "description": null,
    "parent_id": 0,
    "is_parent": false,
    "display_order": 9,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 1,
        "type": "smart",
        "name": "location",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": null,
        "description": null,
        "created_at": "2024-10-08T04:01:20.000000Z",
        "updated_at": "2024-10-08T04:01:20.000000Z"
      }
    ]
  },
  {
    "object_type": "task",
    "id": 18197,
    "uuid": "TTE2-0004",
    "name": "South Loge",
    "description": null,
    "parent_id": 0,
    "is_parent": false,
    "display_order": 10,
    "type": "ops",
    "status": "todo",
    "percent_complete": "0.00",
    "duration_days": 1,
    "cal_days": "1",
    "duration_string": "undefined Work Days",
    "effort_tag": null,
    "effort_hours": "0.00",
    "crew_size": 0,
    "scheduled_start": "2024-10-23",
    "scheduled_end": "2024-10-23",
    "forecasted_start": "2024-10-23",
    "forecasted_end": "2024-10-23",
    "actual_start": null,
    "actual_end": null,
    "autoschedule_date": "schedule",
    "constraint_type": "ASAP",
    "constraint_date": null,
    "qty": null,
    "qty_type": null,
    "earned_days": null,
    "earned_planned_days": null,
    "production_rate": null,
    "work_from": null,
    "remaining_duration": 0,
    "project_id": 186,
    "pace": 0,
    "pr": null,
    "free_float": 0,
    "total_float": 0,
    "critical_path": 1,
    "color": null,
    "optimal_crew": 0,
    "shift": "28800.00",
    "workday_calendar": {
      "sunday": "0",
      "monday": "1",
      "tuesday": "1",
      "wednesday": "1",
      "thursday": "1",
      "friday": "1",
      "saturday": "0"
    },
    "latest_job_walk": null,
    "latest_jobwalk_date": null,
    "latest_jobwalk": null,
    "created_at": "2024-10-23",
    "updated_at": "2024-10-25",
    "timestamp": 1729844534,
    "location_id": null,
    "phase_id": null,
    "zone_id": null,
    "area_id": null,
    "companies": [],
    "contacts": [],
    "responsible_users": [],
    "trades": [],
    "is_production": false,
    "deleted_at": null,
    "open_stuck_points_count": 0,
    "dates_locked_by": null,
    "tags": [
      {
        "object_type": "tag",
        "id": 1,
        "type": "smart",
        "name": "location",
        "created_by": {
          "object_type": "system_user",
          "id": 0,
          "first_name": "BlackHyve",
          "last_name": null,
          "name": "BlackHyve"
        },
        "tagged_by": 2320,
        "smart_key": null,
        "smart_task_id": null,
        "smart_tag_id": null,
        "color": null,
        "description": null,
        "created_at": "2024-10-08T04:01:20.000000Z",
        "updated_at": "2024-10-08T04:01:20.000000Z"
      }
    ]
  }
];

const { api, providesList } = require('api');

export const PROCUREMENT_CACHE_TYPE = 'Procurement';

const procurementAPI = api.enhanceEndpoints({ addTagTypes: [PROCUREMENT_CACHE_TYPE] }).injectEndpoints({
  endpoints: (build) => ({
    createProcurement: build.mutation({
      query: ({ projectId, ...body }) => ({
        url: `/projects/${projectId}/procurements`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error) =>
        result ? [{ id: 'LIST', type: PROCUREMENT_CACHE_TYPE }] : [],
    }),
    updateProcurement: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/procurements/${id}/`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: PROCUREMENT_CACHE_TYPE, id }] : [],
    }),
    getProcurements: build.query({
      query: ({ projectId, ...queryParam }) => ({
        url: `/projects/${projectId}/procurements`,
        params: !isEmpty(queryParam) ? queryParam : {}
      }),
      providesTags: (result) => (result?.data ? providesList(result.data, PROCUREMENT_CACHE_TYPE) : []),
      transformResponse: (response) => response
    }),
    getProcurement: build.query({
      query: (id) => ({
        url: `/procurements/${id}`,
      }),
      providesTags: (result, error, args) =>
        result ? [{ type: PROCUREMENT_CACHE_TYPE, id: result?.id }] : [],
      transformResponse: (response) => response.data,
    }),
    deleteProcurement: build.mutation({
      query: (id) => ({
        url: `/procurements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) =>
        result ? [{ type: 'Procurement', id: params.id }] : [],
    }),
    getProcurementTasks: build.query({
      query: ({ id }) => ({
        url: `/procurements/${id}/tasks`
      }),
      transformResponse: (response) => response.data
    }),
    getTaskProcurements: build.query({
      query: ({ taskId, queryParam }) => ({
        url: `/tasks/${taskId}/procurements`,
        params: !isEmpty(queryParam) ? queryParam : {}
      }),
      providesTags: (result) => (result ? providesList(result, PROCUREMENT_CACHE_TYPE) : []),
      transformResponse: (response) => response.data
    }),
  }),
});

export const {
  useGetProcurementQuery,
  useGetProcurementsQuery,
  useCreateProcurementMutation,
  useUpdateProcurementMutation,
  useDeleteProcurementMutation,
  useGetProcurementTasksQuery,
  useGetTaskProcurementsQuery

} = procurementAPI;
